import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import CreateEntityForm from 'components/Forms/CreateEntityForm';

class CreateEntity extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={<CreateEntityForm />}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CreateEntity;

