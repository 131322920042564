class Notification {

    constructor(title, message, id, type) {
        this.title = title;
        this.message = message;
        this.id = id;
        this.type = type;
    }
}

export default Notification;