import React, { Component } from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Grid,
  Row,
  Col
} from "react-bootstrap";
import axios from 'axios';

import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import SettingsSidebar from 'components/Sidebar/SettingsSidebar';
import { API_URL, PYAPP_URL } from "settings";

class SettingsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: props.match.params.username,
      editMode: false,
      user: {
        address:{}
      }
    }
  }

  getUser = (username) => {
    axios.get(`${PYAPP_URL}/users/${this.state.username}`)
      .then(res => this.setState({ user: res.data.data }))
      .catch(err => err);
  };

  componentDidMount() {
    this.getUser();
  }

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col sm={2} smOffset={1}>
              {SettingsSidebar}
            </Col>
            <Col sm={9}>
              <Card
                title="User Profile"
                content={
                  <form>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "Username",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Username",
                          value: this.state.user.username,
                          disabled: !this.state.editMode
                        },
                        {
                          label: "Email address",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "Email",
                          value: this.state.user.email,
                          disabled: !this.state.editMode
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "First name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "First name",
                          value: this.state.user.firstName,
                          disabled: !this.state.editMode
                        },
                        {
                          label: "Last name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Last name",
                          value: this.state.user.lastName,
                          disabled: !this.state.editMode
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Address",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Street",
                          value: this.state.user.address.street,
                          disabled: !this.state.editMode
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      proprieties={[
                        {
                          label: "City",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "City",
                          value: this.state.user.address.city,
                          disabled: !this.state.editMode
                        },
                        {
                          label: "Country",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Country",
                          value: this.state.user.address.country,
                          disabled: !this.state.editMode
                        },
                        {
                          label: "Postal Code",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "ZIP Code",
                          value: this.state.user.address.zipcode,
                          disabled: !this.state.editMode
                        }
                      ]}
                    />

                    <div className="row">
                      <div className="col-md-12">
                        <FormGroup controlId="formControlsTextarea">
                          <ControlLabel>Notes</ControlLabel>
                          <FormControl
                            rows="5"
                            componentClass="textarea"
                            bsClass="form-control"
                            placeholder="Here can be your description"
                            defaultValue="Notes here"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <Button bsStyle="info" pullRight fill type="submit">
                      Update Profile
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default SettingsPage;
