import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { login, loadProfileFromSession } from 'utils/auth';
import './LoginPage.css';


class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formEmail: '',
      formPassword: '',
      cardHidden: true,
      isAuthenticated: false,
      badAttempt: false
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  async componentWillMount() {
    const profile = await loadProfileFromSession();
    if (profile) {
      this.setState({isAuthenticated: true});
    }
  }

  async componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      400
    );
  }

  handleFormChange = (event) => {
    const obj = this.state;
    obj[event.target.name] = event.target.value;
    this.setState(obj);
    this.setState({badAttempt: false});
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    let { email, password }= {
      email: this.state.formEmail,
      password: this.state.formPassword
    };
    const authToken = await login(email, password);
    if (authToken) {
      this.setState({isAuthenticated: true});
    }
    else {
      this.setState({badAttempt: true});
    }
  }

  render() {
    if (this.state.isAuthenticated) {
      return <Redirect to='/' />
    }
    return (
      <Grid>
        <Row className={this.state.badAttempt ? 'shake' : ''}>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Username</ControlLabel>
                      <FormControl 
                        name="formEmail" 
                        placeholder="username or email" 
                        onChange={this.handleFormChange}
                        value={this.state.formEmail}
                        type="text" 
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl 
                        name="formPassword" 
                        placeholder="password"
                        onChange={this.handleFormChange}
                        value={this.state.formPassword}
                        type="password" 
                      />
                    </FormGroup>
                    { this.state.badAttempt &&
                        <p style={{color: 'rgba(255, 0, 0, 0.6)', textAlign: 'center'}}>Incorrect username or password</p> }
                  </div>
                }
                legend={
                  <Button bsStyle="info" onClick={this.handleFormSubmit} fill wd>
                    Login
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;
