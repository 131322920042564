import React, { Component } from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Grid,
  Row,
  Col,
  HelpBlock
} from "react-bootstrap";
import axios from 'axios';

import Select from "react-select";
import Datetime from "react-datetime";

import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import SweetAlert from "react-bootstrap-sweetalert";
import { API_URL, PYAPP_URL } from "settings";
import 'components/Forms/Forms.css';

const FormError = (props) => (
  <div style={{paddingTop: '7px'}}>
    <small className="text-danger">
      {props.message}
    </small>
  </div>
)

class ZonePageEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      zone: {
        id: props.match.params.zone_id,
        name: "",
        description: ""
      },
      alert: null,
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  getZone = () => {
    axios.get(`${PYAPP_URL}/property_zone/${this.state.zone.id}`)
      .then(res => {
        console.log(res);
        var obj = this.state;
        obj.zone.id = res.data.id;
        obj.zone.name = res.data.name;
        obj.zone.description = res.data.description;
        obj.editMode = true;
        this.setState(obj);
      })
      .catch(err => err);
  };

  componentDidMount() {
    this.getZone();
  }

  getValidationState = (value, validateFunction) => {
    if (value === null || value === '' || !this.state.editMode) return null;
    return validateFunction(value) ? 'success' : 'error';
  }

  handleFormChange = (event) => {
    const obj = this.state;
    var name = event.target.name;
    obj.zone[name] = event.target.value;
    this.setState(obj);
    this.setState({badAttempt: false});
  }

  sendSuccessAlert = () => {
    console.log('setting alert');
    this.setState({ alert: (
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Saved Zone"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="info"
      />
    )});
  }

  sendErrorAlert = (tx) => {
    this.setState({ alert: (
      <SweetAlert
        error
        style={{ display: "block", marginTop: "-100px" }}
        title={tx}
        text={tx}
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="info"
      />
    )});
  }

  hideAlert = () => {
    this.setState({ alert: null });
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;
    let errormsg = '';
    
    let data = {
    name: this.state.zone.name,
    description: this.state.zone.description
    };

    console.log(data);

    if (!isValid) {       
      console.log('Some info is not valid');      
      this.sendErrorAlert(errormsg);  
      /*this.setState({
        emailError: <FormError message='Email should be of the form john@doe.com'/>
      })*/
    }
    else {
      console.log('Will save the zone');  
      axios.put(`${PYAPP_URL}/property_zone/${this.state.zone.id}`, data)  
        .then( res => {          
          this.sendSuccessAlert();
          console.log('Sent success alert');
        })
        .catch( err => {
          console.log(err)
          this.sendErrorAlert('Another zone already exists with this name');
        })
    }
  }

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
            <Row>
              {this.state.alert}  
              <Col md={12}>
              <Card
                title="Zone"
                content={
                  <form method="POST">
                    <Row>                
                      <Col md={12}>
                        <FormGroup>
                          <ControlLabel>
                              Name
                          </ControlLabel>
                          <FormControl
                              type="text"
                              name="name"
                              value={this.state.zone.name}
                              onChange={this.handleFormChange}
                              required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <ControlLabel>
                              Description
                          </ControlLabel>
                          <FormControl
                              componentClass="textarea"
                              name="description"
                              rows="3"
                              value={this.state.zone.description}
                              onChange={this.handleFormChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Button
                        bsStyle="info"
                        fill
                        pullRight
                        submit
                        onClick={this.handleFormSubmit}
                        >
                        Update Zone
                        </Button>
                      </Col>
                    </Row>

                    <div className="clearfix" />
                    </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ZonePageEdit;
