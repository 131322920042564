import Moment from 'moment';


export const checkValidEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const checkValidUsername = (username) => {
  var re = /[a-z]/;
  return re.test(username) && username.length >= 5;
};

export const checkValidPhoneNumber = (number) => {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
  return re.test(number);
};

export const checkValidDateString = (dateString) => {
  // CHECK
  const date = Moment(dateString);
  return date.isValid()
};


export const checkValidPassword = (password) => {
  const eightOrMore = password.length >= 8;
  const oneLowerCase = /[a-z]/.test(password);
  const oneUpperCase = /[A-Z]/.test(password);
  const oneNumber = /\d/.test(password);
  return eightOrMore && oneLowerCase && oneUpperCase && oneNumber;
}
