import Pages from "layouts/Pages/Pages.jsx";
import Dashboard from "layouts/Dashboard/Dashboard.jsx";

var indexRoutes = [
  { path: "/login", name: "Login", component: Pages },
  { path: "/register", name: "Register", component: Pages },
  { path: "/pages", name: "Pages", component: Pages },
  { path: "/password_reset", name: "Password Reset", component: Pages },  
  { path: "/", name: "Home", component: Dashboard }
];

export default indexRoutes;
