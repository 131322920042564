import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Grid,
  Row,
  Col
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { getUser, getUserProperties } from 'api/user';

import EntityTable from 'components/Tables/EntityTable';
import PropertyTable from 'components/Tables/PropertyTable';
import Moment from 'moment';

class UserPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: props.match.params.username,
      editMode: false,
      user: {},
      address: {},
      groups: [],
      properties: [],
      userNotFound: false
    }
  }

  async componentDidMount() {
    try {
      let user = await getUser(this.state.username, false);
      if(user.date_of_birth){
        user.date_of_birth = Moment(user.date_of_birth).format('MM/DD/YYYY');
      }
      this.setState({user: user});
			if (this.state.user.address) {
				this.setState({address: user.address});
			}
			if (this.state.user.groups) {
				this.setState({groups: user.groups});
			}
      let userProps = await getUserProperties(this.state.user.id);
      this.setState({properties: userProps});
    } catch (e) {
      this.setState({userNotFound: true});
    }
  }

  render() {

    if (this.state.userNotFound) {
      return (
        <Redirect to='/404' />
      )
    }
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="User Profile"
                content={
                  <form>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "Email address",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "Email",
                          value: this.state.user.email,
                          disabled: !this.state.editMode
                        },
                        {
                          label: "Username",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Username",
                          value: this.state.user.username,
                          disabled: !this.state.editMode
                        }                        
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "First name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "First name",
                          value: this.state.user.first_name,
                          disabled: !this.state.editMode
                        },
                        {
                          label: "Last name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Last name",
                          value: this.state.user.last_name,
                          disabled: !this.state.editMode
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "Phone Number",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Phone Number",
                          value: this.state.user.phone_number,
                          disabled: true
                        },
                        {
                          label: "Date of Birth",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "",
                          value: this.state.user.date_of_birth,
                          disabled: true
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Address",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Street",
                          value: this.state.address.street,
                          disabled: !this.state.editMode
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      proprieties={[
                        {
                          label: "City",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "City",
                          value: this.state.address.city,
                          disabled: !this.state.editMode
                        },
                        {
                          label: "State",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "State",
                          value: this.state.address.state,
                          disabled: !this.state.editMode
                        },
                        {
                          label: "Zip Code",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "ZIP Code",
                          value: this.state.address.zipcode,
                          disabled: !this.state.editMode
                        }
                      ]}
                    />
                    <Button bsStyle="info" pullRight fill type="submit">
                      Update Profile
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
                <Card
                  title='Properties'
                  content={
                    <PropertyTable 
                      data={this.state.properties} 
                      onClick={(id) => this.props.history.push(`/property/${id}`)} 
                    />
                  }
                />
            </Col>
            <Col md={6}>
                <Card
                  title='Entities'
                  content={
                    <EntityTable 
                      data={this.state.groups} 
                      onClick={(id) => this.props.history.push(`/entity/${id}`)} 
                    />
                  }
                />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default UserPage;