import axios from 'axios';
import { API_URL, PYAPP_URL } from "settings";
import { queryAPI } from 'api/general';
import { getUserProperties } from 'api/user';
import { isFutureDate } from 'utils/date';


export async function getProperty(prop_id) {
    return axios.get(`${PYAPP_URL}/property/${prop_id}`).then(x => x.data)
}

export async function getPropertyDocuments(prop_id) {
    return axios.get(`${PYAPP_URL}/property/${prop_id}/documents`).then(x => x.data.objects)
}

// export async function getPropertyEvents(prop_id) {
//     return axios.get(`${PYAPP_URL}/property/${prop_id}/events`).then(x => x.data.objects)
// }
// FIXME: not right
export async function getPropertyEvents(prop_id) {
    return axios.get(`${PYAPP_URL}/property/${prop_id}/events`).then(x => x.data.objects)
}

export async function getPropertyImages(prop_id) {
    let images = await getPropertyDocuments(prop_id);
    images = images.filter(x => x.document_type.name === 'image');
    return images.map(x => {
        return x
    });
}

// NEW
export async function getAllUserProperties(user_id) {
    return axios.get(`${PYAPP_URL}/user_properties/${user_id}`).then(x => x.data);
}

export async function getAllPropertiesFast() {
    return axios.get(`${PYAPP_URL}/all_properties`).then(x => x.data);
}

export async function getAllProperties() {
    return axios.get(`${PYAPP_URL}/property`).then(x => x.data.objects);
}

export async function getActiveProperties(userId) {
  let allProperties = await getUserProperties(userId);
  let activeProps = allProperties.filter(x => !isFutureDate(Date.parse(x.closing_date)));
  return activeProps;
}

export async function createProperty(data) {
    return axios.post(`${PYAPP_URL}/property`, data);
}

export async function savePropertyFiles(data) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        }
    }
    return axios.post(`${PYAPP_URL}/properties/files`, data, axiosConfig);
}

export async function saveProperty(pid, data) {
    return axios.put(`${PYAPP_URL}/property/` + pid, data)
}

