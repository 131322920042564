import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import 'react-perfect-scrollbar/dist/css/styles.css';

import HeaderLinks from "components/Header/HeaderLinks.jsx";

// backgroundImage for Sidebar
import image from "assets/img/desert-background.jpg";
// image for avatar in Sidebar
import avatar from "assets/img/faces/face-0.jpg";

import { dashboardRoutes } from "routes/dashboard.jsx";
import './Sidebar.css';
import logo from 'assets/img/full-logo-white.png';

const bgImage = { backgroundImage: "url(" + image + ")" };


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openSetting: false,
      openAdmin: this.activeRoute("/admin") !== "" ? true : false,
      isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth
    };
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
		//return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
		return this.props.location.pathname === routeName ? "active" : "";
  }
  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    // add event listener for windows resize
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    const profile = this.props.profile;
    return (
      <div className="sidebar" data-color="black" data-image={image} style={{overflow: 'hidden'}}>
        <div className="sidebar-background" style={bgImage} />
        <div className="logo">
          <a href="http://langleyland.com" className="simple-text logo-normal">
            <img src={logo} height='62px'/>
          </a>
        </div>
        <div className="sidebar-wrapper">
          <div className="user">
            <div className="photo">
              <img src={avatar} alt="Avatar" />
            </div>
            <div className="info">
							{/* eslint-disable-next-line */}
              <a
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  {profile.firstName &&
                      profile.firstName + ' ' + profile.lastName}
                  {!profile.firstName &&
                      profile.username}
                  <b
                    className={
                      this.state.openAvatar ? "caret rotate-180" : "caret"
                    }
                  />
                </span>
              </a>
              <Collapse in={this.state.openAvatar}>
                <ul className="nav">
                  <li>
										{/* eslint-disable-next-line */}
                    <NavLink to={`/user/${profile.username}`}>
                      <span className="sidebar-mini">MP</span>
                      <span className="sidebar-normal">My Profile</span>
                    </NavLink>
                  </li>
                  <li>
										{/* eslint-disable-next-line */}
                    <NavLink to={`/user/${profile.username}/edit`}>
                      <span className="sidebar-mini">EP</span>
                      <span className="sidebar-normal">Edit Profile</span>
                    </NavLink>
                  </li>
                  <li>
										{/* eslint-disable-next-line */}
                    <NavLink to={`/settings`}>
                      <span className="sidebar-mini">S</span>
                      <span className="sidebar-normal">Settings</span>
                    </NavLink>
                  </li>
                  <li>
                    {/* eslint-disable-next-line */}
                      <a onClick={this.props.logout}>
                        <div className='text-danger'>
                        <span className="sidebar-mini"><i className="pe-7s-close-circle"/></span>
                        <span className="sidebar-normal">Logout</span>
                        </div>
                      </a>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <div className="user" style={{margin: '5px', paddingBottom: '5px'}}>
            <ul className="nav">
              <li
                className={this.activeRoute('/dealroom')}
                key={45}>
                <NavLink
                  to='/dealroom'
                  className="nav-link"
                  activeClassName="active">
                  <i className='pe-7s-portfolio'/>
                  <p>Deal Room</p>
                </NavLink>
              </li>
            </ul>
          </div>

          <ul className="nav">
            {this.state.width <= 992 ? ( 
                <HeaderLinks 
                  notifications={this.props.notifications} 
                  lockScreen={this.props.lockScreen}
                  logout={this.props.logout}
                  isAdmin={this.props.isAdmin}
                  isAdminPage={this.props.isAdminPage}
                /> 
                ) : ( null )}
            {dashboardRoutes.map((prop, key) => {
              var st = {};
              st[prop["state"]] = !this.state[prop.state];
              if (prop.requireAdmin && !this.props.isAdmin) {
                console.log('skipping')
              } else {
                if (prop.collapse) {
                  return (
                    <li className={this.activeRoute(prop.path)} key={key}>
                      {/* eslint-disable-next-line */}
                      <a onClick={() => this.setState(st)}>
                        <i className={prop.icon} />
                        <p>
                          {prop.name}
                          <b
                            className={
                              this.state[prop.state]
                                ? "caret rotate-180"
                                : "caret"
                            }
                          />
                        </p>
                      </a>
                      <Collapse in={this.state[prop.state]}>
                        <ul className="nav">
                          {prop.views.map((prop, key) => {
                            return (
                              <li
                                className={this.activeRoute(prop.path)}
                                key={key}
                              >
                                <NavLink
                                  to={prop.path}
                                  className="nav-link"
                                  activeClassName="active"
                                >
                                  <span className="sidebar-mini">
                                    {prop.mini}
                                  </span>
                                  <span className="sidebar-normal">
                                    {prop.name}
                                  </span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  );
                } else {
                  if (prop.redirect) {
                    return null;
                  } else {
                    return (
                      <li className={this.activeRoute(prop.path)} key={key}>
                        <NavLink
                          to={prop.path}
                          className="nav-link"
                          activeClassName="active"
                        >
                          <i className={prop.icon} />
                          <p>{prop.name}</p>
                        </NavLink>
                      </li>
                    );
                  }
                }
              }
              
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;