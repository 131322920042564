import React from 'react'


const SwitchArrows = () => (
  <svg 
    viewBox="0 0 24 24" 
    role="presentation" 
    aria-hidden="true" 
    focusable="false" 
    style={{height: '22px', width: '22px', display: 'block', fill: 'currentcolor'}}>
   <path d="m23.85 14.85a.5.5 0 0 1 -.71 0l-3.14-3.14v7.56c0 2.65-1.75 4.73-4 4.73-2.26 0-4-2.08-4-4.74v-14.52c0-2.14-1.34-3.74-3-3.74s-3 1.6-3 3.74v8.56l3.15-3.15a.5.5 0 0 1 .71.71l-4 4a .5.5 0 0 1 -.71 0l-4-4a .5.5 0 0 1 .71-.71l3.14 3.14v-8.55c0-2.66 1.75-4.74 4-4.74 2.26 0 4 2.08 4 4.74v14.53c0 2.14 1.34 3.73 3 3.73s3-1.6 3-3.74v-7.56l-3.15 3.15a.5.5 0 0 1 -.71-.71l4-4a .5.5 0 0 1 .71 0l4 4a .5.5 0 0 1 0 .71" fillRule="evenodd"></path>
  </svg>
)

export default SwitchArrows;