import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { 
  Grid,
  Row,
  Col,
  Media,
  Form,
  FormControl,
  FormGroup
} from "react-bootstrap";
import axios from "axios";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";
import { checkValidPassword, checkValidEmail } from 'utils/validation';
import { createUser } from "../../api/user";

const FormError = (props) => (
  <div style={{paddingTop: '7px'}}>
    <small className="text-danger">
      {props.message}
    </small>
  </div>
)

class RegisterPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      cfPassword: "",

      emailError: false,
      usernameEror: false,
      passwordError: false
    }
  }

  handleFormChange = (event) => {
    const obj = this.state;
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
    if (!checkValidPassword(event.target.value) && event.target.value !== "") {
      this.setState({
        passwordError: <FormError message={
          <ul>
            <li>Eight or more characters</li>
            <li>At least one lower case</li>
            <li>At least one upper case</li>
            <li>At least one number</li>
          </ul>
        }/>
      })
    } else {
      this.setState({passwordError: null});
    }
  }

  getValidationStateEmail = () => {
    if (this.state.email === "") return null;
    if (!checkValidEmail(this.state.email)) return 'error';
    return 'success'
  }

  getValidationStatePassword = () => {
    if (this.state.password === "") return null;
    if (!checkValidPassword(this.state.password)) return 'error';
    return 'success'
  }

  getValidationStateCfPassword = () => {
    if (this.state.cfPassword === "") return null;
    if (this.state.password !== this.state.cfPassword) return 'error';
    return 'success'
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    let data = {
      username: this.state.username,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password
    };
    console.log(data);

    // check if valid
    if (!checkValidPassword(this.state.password)) {
      this.setState({
        passwordError: <FormError message={
          <ul>
            <li>Eight or more characters</li>
            <li>At least one lower case</li>
            <li>At least one upper case</li>
            <li>At least one number</li>
          </ul>
        }/>
      })
    }
    else if (!checkValidEmail(this.state.email)) {
      this.setState({
        emailError: <FormError message='Email should be of the form john@doe.com'/>
      })
    }
    else {
      createUser(data)
        .then( res => {
          console.log(res)
          if (res.status === 201) {
            this.props.sendNotification('Registered User!', 'success');
            setTimeout(function(props){
              props.history.push('/login');
          }, 1250, this.props);
          } 
        })
        .catch( err => {
          console.log(err)
          this.props.sendNotification(
            'Username or email already exists', 
            'error',
            'pe-7s-delete-user'
          );
        })
    }
  }


  render() {
    if (this.state.isAuthenticated) {
      return <Redirect to='/' />
    }
    return (
      <Grid>
        <Row>
          <Col md={8} mdOffset={2}>
            <div className="header-text">
              <h2>Langley Properties</h2>
              <h4>Investor Management System</h4>
              <hr />
            </div>
          </Col>
          <Col md={4} mdOffset={2}>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-user" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Create Account</Media.Heading>
                Free account to securely manage and monitor
                investments
              </Media.Body>
            </Media>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-graph1" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Awesome Performances</Media.Heading>
                Increased investor transparency with real time updates
                and analytics
              </Media.Body>
            </Media>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-headphones" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Quality Support</Media.Heading>
                  Updates, notifications, and support
              </Media.Body>
            </Media>
          </Col>
          <Col md={4}>
            <Form
              onSubmit={this.handleFormSubmit} 
            >
              <Card
                plain
                content={
                  <div>
                    <FormGroup>
                      <FormControl 
                        name="firstName"
                        type="text" 
                        placeholder="Your First Name" 
                        value={this.state.firstName}
                        onChange={this.handleFormChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControl 
                        name="lastName"
                        type="text" 
                        placeholder="Your Last Name" 
                        value={this.state.lastName}
                        onChange={this.handleFormChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControl 
                        name="username"
                        type="text" 
                        placeholder="Username" 
                        value={this.state.username}
                        onChange={this.handleFormChange}
                      />
                    </FormGroup>
                    <FormGroup validationState={this.getValidationStateEmail()}>
                      <FormControl 
                        name="email"
                        type="email" 
                        placeholder="Enter Email" 
                        value={this.state.email}
                        onChange={this.handleFormChange}
                      />
                      {this.state.emailError}
                    </FormGroup>
                    <FormGroup validationState={this.getValidationStatePassword()}>
                      <FormControl 
                        name="password"
                        type="password" 
                        placeholder="Password" 
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                      />
                      {this.state.passwordError}
                    </FormGroup>
                    <FormGroup validationState={this.getValidationStateCfPassword()}>
                      <FormControl 
                        name="cfPassword"
                        type="password" 
                        placeholder="Password Confirmation" 
                        value={this.state.cfPassword}
                        onChange={this.handleFormChange}
                      />
                      {this.state.cfPasswordError}
                    </FormGroup>
                  </div>
                }
                ftTextCenter
                legend={
                  <Button type="submit" wd fill neutral>
                    Create Account
                  </Button>
                }
              />
            </Form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default RegisterPage;
