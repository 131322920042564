import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import Card from 'components/Card/Card'

import Wizard from 'views/Property/Wizard/Wizard';
import AddPropertyForm from 'components/Forms/AddPropertyForm';

class CreateProperty extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title='Add Property'
                content={<AddPropertyForm />}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CreateProperty;