import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Table,
} from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";


class SimpleUserTable extends Component {

  render() {
		const entries = this.props.users.map((x, i) => {
			const actions = (
				<td className="td-actions text-right">
					<Button simple bsStyle="danger" bsSize="xs" onClick={() => this.props.action(x)}>
						<i className="fa fa-times" />
					</Button>
				</td>
			);
			return (
			<tr>
				<td className="text-center">{i+1}</td>
				<td>{x.first_name + ' ' + x.last_name}</td>
				{actions}
			</tr>
			)
		});

    return (
      <div>
        <Grid fluid>
          <Row>
            <Col md={12}>
							<Table responsive>
							<thead>
									<tr>
									<th className="text-center">#</th>
									<th>Name</th>
									<th className="text-right">Remove</th>
									</tr>
							</thead>
								{entries}
							<tbody>
							</tbody>
							</Table>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default SimpleUserTable;
