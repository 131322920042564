import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Nav,
  NavItem,
  Tab
} from "react-bootstrap";
import { Card } from 'semantic-ui-react';

import PropertyCard from "components/Card/PropertyCard";
import './DealRoom.css';

import { getAllProperties, getAllPropertiesFast } from 'api/property';


class DealRoom extends Component {

  constructor(props) {
		super(props);
		this.state = {
			properties: []
		}
	}
	
	componentWillMount() {
    getAllPropertiesFast()
			.then(properties => this.setState({ properties: properties.data}))
	}

  render() {
    return (

      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card.Group className='card-group'>
								{
									this.state.properties.map((prop, i) => {
                    return <PropertyCard property={prop} onClick={() => this.props.history.push(`/property/${prop.id}`)}/>
									})
								}
							</Card.Group>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default DealRoom;