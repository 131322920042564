import React, { Component } from "react";
import { 
  Grid, 
  Row, 
  Col
} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

import AdminZoneTable from "components/Tables/AdminZoneTable";
import Card from "../../components/Card/Card";
import { getAllZones } from 'api/zone';
import AddZoneForm from 'components/Forms/AddZoneForm';

class AdminZones extends Component {

  constructor(props) {
    super(props);
    this.state = {
      zones: [],
      alert: null
    };
  };

  componentDidMount() {
    getAllZones().then(x => {
     console.log('get all zones');
     console.log(x);   
     this.setState({ zones: x });   
    });
  }

  handleChange = (event) => {
    const obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  successAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{display: "block",marginTop: "-100px"}}
          title="Good Job Kid"
          onConfirm = {() => this.setState({alert: null})}
          onCancel = {() => this.setState({alert: null})}
          confirmBtnBsStyle="info"
        >
        Hi there
        </SweetAlert>
      )
    })
  }

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card content={ <AdminZoneTable zones={this.state.zones}/> } />
            </Col>
          </Row>
			<Row>
				<Col md={6}>
					<Card title='Add Zone' content={ <AddZoneForm/>} />
				</Col>
			</Row>          
        </Grid>
      </div>
    );
  }
}

export default AdminZones;
