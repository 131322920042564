import React from "react";
import ReactTable from "react-table";
import { NavLink } from "react-router-dom";
import Button from "components/CustomButton/CustomButton.jsx";


const UserTable = (props) => {

    const tableProperties = props.users.map(user => {

        let userRole;
        try {
            userRole = user.roles[0].name;
        } catch (e) {
            userRole = 'none';
        }

        return {
            id: user.id,
            username: user.username,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            role: userRole,
            actions: (
                <div className="actions-right">
                    <NavLink to={`/user/${user.username}`}>
                        <Button
                        bsStyle="info"
                        simple
                        icon
                        >
                        <i className="fa fa-eye" />
                        </Button>{" "}
                    </NavLink>
                    <NavLink to={`/user/${user.username}/edit`}>
                        <Button
                        bsStyle="warning"
                        simple
                        icon
                        >
                        <i className="fa fa-edit" />
                        </Button>
                    </NavLink>
                </div>
                )
        };
    });

    return (
        <ReactTable
            data={tableProperties}
            filterable
            columns={[
                {
                Header: "User Name",
                accessor: "username"
                },
                {
                Header: "Email",
                accessor: "email"
                },
                {
                Header: "First Name",
                accessor: "first_name"
                },
                {
                Header: "Last Name",
                accessor: "last_name"
                },
                {
                Header: "Role",
                accessor: "role"
                },
                {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
                }
            ]}
            defaultPageSize={10}
            showPaginationBottom={true}
            className="-striped -highlight"
        />
    )
}


export default UserTable;