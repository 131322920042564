import React, { Component } from "react";
import { 
  Grid, 
  Row, 
  Col
} from "react-bootstrap";

import Card from "../../components/Card/Card";
import CreateEntity from 'views/Entities/CreateEntity';


class AdminEntities extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card content={ <CreateEntity /> } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AdminEntities;
