import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";

class HelpCenter extends Component {
  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col>
							<Card
								content={
									<div>
										<h2>So you need a little help?</h2>
										<p>
												We want you to be happy, contact support at (480) 633-1500
										</p>
									</div>
								}
							/>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default HelpCenter;