import React, { Component } from 'react';

import { Document, Page } from 'react-pdf';


export class PDFPreview extends Component {
  render() {
    return (
      <div className="react-pdf__Document-thumbnail">
        <Document
            file={this.props.pdf}
            renderMode="svg"
            onLoadSuccess={this.onDocumentLoadSuccess}
            className="react-pdf__Document-document" >
            <Page 
                pageNumber={1}
                width={250}
                className="react-pdf__Document-page" />
        </Document>
      </div>
    )
  }
}

export default PDFPreview;
