import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  ProgressBar
} from 'react-bootstrap';
import { Card, Icon, Image } from 'semantic-ui-react'
import Moment from 'moment';
import 'semantic-ui-css/semantic.min.css';
import Numeral from "numeral";


import './PropertyCard.css';

const round2Decimal = (num) => {
  return Math.round(num * 100) / 100
}

const PropertyRow = (props) => {
  return (
    <div className='property-row'>
    <Grid fluid>
    <Row>
      <Col xs={6} >
        <p style={{fontSize: '12px'}}>{props.name}</p>
      </Col>
      <Col xs={6}>
        <p className='pull-right' style={{fontSize: '12px'}}>{props.value}</p>
      </Col>
    </Row>
    </Grid>
    </div>
  )
}

export class PropertyCard extends Component {
  render() {
    const prop = this.props.property;
    const rows = [
      {
        'name': 'Investment Type',
        'value': 'Equity'
      },
      {
        'name': 'Est. Rate of Return',
        'value': round2Decimal(prop.est_return)
      },
      {
        'name': 'Closing Date',
        'value': Moment(new Date()).format('MM/DD/YYYY')
      },
      {
        'name': 'Total Equity',
        'value': Numeral(prop.purchase_price).format('$,0.00')
      }
    ];
    const propDescription = rows.map((x, i) => {
      return (
        <React.Fragment>
        <PropertyRow name={x.name} value={x.value} />
        {i !== 3 && //dont do last one
          <hr style={{marginTop: 8, marginBottom: 8}}/>
        }
        </React.Fragment>
      )
    })

    return (
      <div className='property-card' onClick={this.props.onClick}>
        <Card>
          <Image src={prop.photo} />
          <Card.Content>
            <Card.Header>{prop.name}</Card.Header>
            <Card.Description>
              {propDescription}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
          <p style={{fontSize: '11px'}}>{round2Decimal(prop.percent_funded * 100)}% funded</p>
          <ProgressBar
            bsStyle="success"
            now={prop.percent_funded * 100}
            className="progress-line-info"
            srOnly
          />
          </Card.Content>
        </Card>
      </div>
    );
  }
}

export default PropertyCard;
