import React, { Component } from "react";

import SettingsPageLayout from "./SettingsPageLayout";


class SettingsPropertiesPage extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <SettingsPageLayout
        content={
          <div>
            <h3>Manage Notifications</h3>
            <hr/>
          </div>
        }
      />
    );
  }
}

export default SettingsPropertiesPage;




