import React, { Component } from "react";
import { 
  Grid, 
  Row, 
  Col
} from "react-bootstrap";
import axios from 'axios';
import { getAllUsers } from 'api/user'
import { API_URL, PYAPP_URL } from "settings";

import Card from "../../components/Card/Card";
import UserTable from 'components/Tables/UserTable';
import AddUserForm from 'components/Forms/AddUserForm';
import AddPropertyForm from 'components/Forms/AddPropertyForm';


class AdminUsers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      formAddUsername: '',
      formAddEmail: '',
      alert: null,
    };

  };

  componentDidMount() {
    getAllUsers(10,1).then(x => this.setState({ users: x}));
    getAllUsers().then(x => this.setState({ users: x}));
  }

  addUser = (event) => {
    event.preventDefault();

    const data = {
      username: this.state.formAddUsername,
      email: this.state.formAddEmail,
      password: 'test',
      alert: null
    }

    axios.post(`${PYAPP_URL}/users`, data)
      .then(res => this.getUsers())
      .catch(err => err);
  };

  deleteUser = (event) => {
    console.log(event);
  }

  handleChange = (event) => {
    const obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card content={ <UserTable users={this.state.users}/> } />
            </Col>
          </Row>
					<Row>
						<Col md={6}>
							<Card title='Add User' content={ <AddUserForm/>} />
						</Col>
					</Row>
        </Grid>
      </div>
    );
  }
}

export default AdminUsers;
