import React, { Component } from "react";
import './Timeline.css';
import Moment from 'moment';


const TimelineCard = (props) => {
    return (
        <div className='timeline-card'>
            <div className='timeline-content'>
                <p className='timeline-title'><b></b></p>
                <p className='timeline-subtitle'>{props.title}</p>
            
            </div>  
        </div>
    )
};

const TimelineElement = (props) => {
    return (
        <div className='timeline-element'>
            <div className='timeline-side'>
                <div className='timeline-dot'></div>
                <div className='timeline-line'></div>
            </div>
            <div className='timeline-content'>
                <p className='timeline-date'>{props.date}</p>
                <TimelineCard title={props.title} />
            </div>
        
        </div>
    )
}

class Timeline extends Component {
  render() {
    return (
			<div className='timeline'>
				{
					this.props.events.map(x => {
						return (
						<TimelineElement title={x.event_type.name} date={Moment(x.date).format('MMMM Do, YYYY')} />
						)
					}
				)}
			</div>
    )
  }
}

export default Timeline;
