import React from "react";
import { NavLink } from "react-router-dom";
import ReactTable from "react-table";
import Numeral from "numeral";
import Moment from "moment";

import Button from "components/CustomButton/CustomButton.jsx";


const AdminZoneTable = (zones) => {
    console.log('Admin Zone Table');
    console.log(zones);
    const tableZones = zones.zones.map((zone, key) => {
          return {
            id: key,
            name: zone.name,
            description: zone.description,
            actions: (
              <div className="actions-right">
                <NavLink to={`/zone/${zone.id}/edit`}>
                  <Button
                  bsStyle="warning"
                  simple
                  icon
                  >
                    <i className="fa fa-edit" />
                  </Button>
                </NavLink>
              </div>
            )
          };
    })

    return (
        <ReactTable
        data={tableZones}
        filterable
        columns={[
            {
            Header: "Name",
            accessor: "name"
            },
            {
            Header: "Description",
            accessor: "description",
            sortable: false
            },
            {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false
            }
        ]}
        defaultPageSize={10}
        showPaginationBottom={true}
        className="-striped -highlight"
        />
    )
}


export default AdminZoneTable;