import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'


// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";

import Sidebar from "components/Sidebar/Sidebar.jsx";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";

import axios from "axios";

import { dashboardRoutes, subRoutes } from "routes/dashboard.jsx";

// style for notifications
import { style } from "variables/Variables.jsx";
import * as mock from "data/mock.js";

import { loadProfileFromSession, clearSession } from 'utils/auth';

var ps;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSidebar: true,
      lockScreen: false,
      _notificationSystem: {},
      notifications: [],
      isAuthenticated: false,
      isAdmin: false,
      isAdminPage: this.props.location.pathname === '/admin/dashboard',
      checkedSessionForProfile: false,
      profile: null
    };
  }

  async componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    
    // check localStorage for token and get profile if exists
    const profile = await loadProfileFromSession();
    if (profile) {
      this.setState({
        isAuthenticated: true,
        profile: profile,
        isAdmin: profile.is_admin
      });
    }
    const authToken = window.localStorage.getItem('authToken');
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
    this.setState({checkedSessionForProfile: true});
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
  }
}

  logout = () => {
    clearSession();
    this.setState({
      isAuthenticated: false,
      profile: null
    });
  }

  lockScreen = () => {
    clearSession();
    this.setState({
      isAuthenticated: false,
      lockScreen: true
    });
  }

  toggleSidebar = () => {
    this.setState({showSidebar: !this.state.showSidebar});
    document.body.classList.toggle("sidebar-mini");
    document.body.classList.toggle("sidebar-gone");
  }
  sendNotification = (message, level, icon="pe-7s-check") => {
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className={icon} />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: level,
      position: 'tl',
      autoDismiss: 5
    });

  }

  render() {
    let view;
    if (!this.state.isAuthenticated && this.state.checkedSessionForProfile) {
      view = <Redirect to='/login' />
    }
    else if (!this.state.isAuthenticated && this.state.lockScreen) {
      view = <Redirect to='/login' />
    }
    else if (!this.state.profile) {
      const loadingCSS = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }
      view = <h1 style={loadingCSS}>Loading...</h1>
    }
    else {
      view = (
        <React.Fragment>
        {this.state.showSidebar &&
                <Sidebar 
                  profile={this.state.profile} 
                  isAdmin={this.state.isAdmin}
                  isAdminPage={this.state.isAdminPage}
                  notifications={this.state.notifications} 
                  logout={this.logout} 
                  lockScreen={this.lockScreen} 
                  {...this.props} 
                />
              }
              <div
                className={
                  "main-panel" +
                  (this.props.location.pathname === "/maps/full-screen-maps"
                    ? " main-panel-maps"
                    : "")
                }
              >
              <PerfectScrollbar>
                <Header 
                  notifications={this.state.notifications} 
                  toggleSidebar={this.toggleSidebar}
                  logout={this.logout} 
                  lockScreen={this.lockScreen} 
                  isAdmin={this.state.isAdmin}
                  isAdminPage={this.state.isAdminPage}
                  userName={this.state.profile.username}
                  {...this.props} 
                />
                <Switch>
                  {subRoutes.map((x, i) => {
                    return <Route
                      exact
                      path={x.path}
                      component={x.component}
                      key={i*20}
                    />
                  })}
                  {dashboardRoutes.map((prop, key) => {
                    if (prop.collapse) {
                      return prop.views.map((prop, key) => {
                        return (
                          <Route
                            path={prop.path}
                            key={key}
                            render={routeProps => (
                              <prop.component
                                {...routeProps}
                                sendNotification={this.sendNotification}
                              />
                            )}
                          />
                        );
                      });
                    } else {
                      if (prop.redirect)
                        return (
                          <Redirect from={prop.path} to={prop.pathTo} key={key} />
                        );
                      else
                        return (
                          <Route
                            path={prop.path}
                            key={key}
                            render={routeProps => (
                              <prop.component
                                {...routeProps}
                                sendNotification={this.sendNotification}
                              />
                            )}
                          />
                        );
                    }
                  })}
                </Switch>
                <Footer fluid />
              </PerfectScrollbar>
            </div>
          </React.Fragment>
        )
      }

    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        {view} 
      </div>
    );
  }
}

export default Dashboard;