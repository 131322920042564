import React, { Component } from 'react'
import { Grid, Row, Col} from "react-bootstrap";
import axios from "axios"

import PDFPreview from "components/PDF/PDFPreview.jsx";
import FileBrowser, { FileRenderers, FolderRenderers, Groupers, Icons } from 'react-keyed-file-browser';
import Moment from 'moment'

import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { loadProfileFromSession } from 'utils/auth';
import { getUserDocuments } from 'api/user';

import './DocumentManager.css';


import PropTypes from 'prop-types'

class Detail extends React.Component {
  static propTypes = {
    file: PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
      url: PropTypes.string,
    }).isRequired,
    close: PropTypes.func,
  }

  handleCloseClick = (event) => {
    if (event) {
      event.preventDefault()
    }
    this.props.close()
  }

  render() {
    let name = this.props.file.key.split('/')
    name = name.length ? name[name.length - 1] : ''

    return (
      <div>
        <h2>Item Detail</h2>
        <dl>
          <dt>Key</dt>
          <dd>{this.props.file.key}</dd>

          <dt>Name</dt>
          <dd>{name}</dd>
        </dl>
        <a href="#" onClick={this.handleCloseClick}>Close</a>
      </div>
    )
  }
}



class DocumentManager extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            documents: [],
            myDocuments: [],
            myFiles: [],
            groupBy: 'property',
            selectedFile: null,
            files: [],
        }
      }

    makeSubfolderPath = (fileName, dirList) => {
      dirList.push(fileName);
      return dirList.join('/');
    }

    componentDidMount() {
    loadProfileFromSession()
      .then(x => this.setState({ profile: x}))
      .then(() => getUserDocuments(this.state.profile.id))
      .then(x => this.setState({documents: x}))
      .then(() => {
        let myDocuments = this.state.documents.map(doc => {
          const myArray = ['Puerto Rico Quina', 'Rougeplant']
          var randomItem = myArray[Math.floor(Math.random()*myArray.length)];
          return {
            key: this.makeSubfolderPath(doc.name, [randomItem]),
            relativeKey: doc.key,
            modified: new Date(doc.updated_at).getTime(),
            size: doc.size,
            url: doc.url
          }
        });
        this.setState({files: myDocuments});
      })
    }

     // _______
     handleCreateFolder = (key) => {
        this.setState(state => {
          state.files = state.files.concat([{
            key: key,
          }])
          return state
        })
      }
      handleCreateFiles = (files, prefix) => {
        this.setState(state => {
          const newFiles = files.map((file) => {
            let newKey = prefix
            if (prefix !== '' && prefix.substring(prefix.length - 1, prefix.length) !== '/') {
              newKey += '/'
            }
            newKey += file.name
            return {
              key: newKey,
              size: file.size,
              modified: +Moment(),
            }
          })
    
          const uniqueNewFiles = []
          newFiles.map((newFile) => {
            let exists = false
            state.files.map((existingFile) => {
              if (existingFile.key === newFile.key) {
                exists = true
              }
              return true // FIXME: just to return val and remove error
            })
            if (!exists) {
              uniqueNewFiles.push(newFile)
            }
            return true // FIXME: just to return val and remove error
          })
          state.files = state.files.concat(uniqueNewFiles)
          return state
        })
      }
      handleRenameFolder = (oldKey, newKey) => {
        this.setState(state => {
          const newFiles = []
          state.files.map((file) => {
            if (file.key.substr(0, oldKey.length) === oldKey) {
              newFiles.push({
                ...file,
                key: file.key.replace(oldKey, newKey),
                modified: +Moment(),
              })
            } else {
              newFiles.push(file)
            }
            return true // FIXME: just to return val and remove error
          })
          state.files = newFiles
          return state
        })
      }
      handleRenameFile = (oldKey, newKey) => {
        this.setState(state => {
          const newFiles = []
          state.files.map((file) => {
            if (file.key === oldKey) {
              newFiles.push({
                ...file,
                key: newKey,
                modified: +Moment(),
              })
            } else {
              newFiles.push(file)
            }
            return true // FIXME: just to return val and remove error
          })
          state.files = newFiles
          return state
        })
      }
      handleDeleteFolder = (folderKey) => {
        this.setState(state => {
          const newFiles = []
          state.files.map((file) => {
            if (file.key.substr(0, folderKey.length) !== folderKey) {
              newFiles.push(file)
            }
            return true // FIXME: just to return val and remove error
          })
          state.files = newFiles
          return state
        })
      }
      handleDeleteFile = (fileKey) => {
        this.setState(state => {
          const newFiles = []
          state.files.map((file) => {
            if (file.key !== fileKey) {
              newFiles.push(file)
            }
            return true // FIXME: just to return val and remove error
          })
          state.files = newFiles
          return state
        })
      }
    //_________
      
    onFileChange = (event) => {
        this.setState({
            document: event.target.files[0]
        });

        const data = new FormData();
        data.append('file', this.uploadInput);

        axios.post(`http://document_manager:5000/api/doc_manager/upload`, data)
        .then(res => console.log(res))
        .catch(err => console.log(err));
    }

    onPreviewOpen = (e) => {
      this.setState({selectedFile: e});
    }

    render() {
      const selectedFile = this.state.selectedFile;
      let preview = null;
      if (selectedFile !== null) {
        const previewVisual = selectedFile.extension.match(/pdf/) ? (
          <PDFPreview pdf={selectedFile.url} />
        ):(
          <img src={selectedFile.url} />
        );
        preview = (
          <div>
          <Row>
            <Col md={12}>
              <div className='manager-preview'>
                {previewVisual}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{paddingTop: '15px'}}>
              <a href={selectedFile.url} download>
              <Button bsStyle="info" fill pullRight>
                Download
              </Button>
              </a>
            </Col>
          </Row>
          </div>
        )
      }
        return (
        <div className="main-content">
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Card
                    title="File Browser"
                    content={
                        <div>
                          <Row>
                            <Col md={12}>
                              <FileBrowser
                                className='tent'
                                files={this.state.files}
                                icons={Icons.FontAwesome(4)}

                                onCreateFolder={this.handleCreateFolder}
                                onCreateFiles={this.handleCreateFiles}
                                onMoveFolder={this.handleRenameFolder}
                                onMoveFile={this.handleRenameFile}
                                onRenameFolder={this.handleRenameFolder}
                                onRenameFile={this.handleRenameFile}
                                onDeleteFolder={this.handleDeleteFolder}
                                onDeleteFile={this.handleDeleteFile}
                                onPreviewOpen={this.onPreviewOpen}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              {preview}
                            </Col>
                          </Row>
                        </div>
                        }
                      />
                  </Col>
                </Row>
            </Grid>
        </div>
        )
    }
}

export default DocumentManager