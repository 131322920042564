import React, { Component } from "react";
import { FormGroup, FormControl } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import avatar from "assets/img/faces/face-0.jpg";
import user from "data/user.js";

class LockScreenPage extends Component {
  render() {
    return (
      <form className="ng-untouched ng-pristine ng-valid">
        <div className="user-profile">
          <div className="author">
            <img alt="..." className="avatar" src={avatar} />
          </div>
          <h4>{user.firstName + ' ' + user.lastName}</h4>
          <FormGroup>
            <FormControl type="password" placeholder="Enter Password" />
          </FormGroup>
          <Button wd neutral round>
            Unlock
          </Button>
        </div>
      </form>
    );
  }
}

export default LockScreenPage;
