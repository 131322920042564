import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from 'semantic-ui-react';

import { getAllUserProperties } from 'api/property'
import { getUserProperties } from 'api/user'
import PropertyCard from "components/Card/PropertyCard";
import { loadProfileFromSession } from 'utils/auth';

class Properties extends Component {

  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      profile: {},
      properties: []
    }
  }

  async componentDidMount() {
    loadProfileFromSession()
      .then(x => this.setState({ profile: x}))
      .then(() => getUserProperties(this.state.profile.id))
      .then(x => this.setState({properties: x}))
      .catch(err => console.log(err));
  }

  render() {

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card.Group className='card-group'>
								{
									this.state.properties.map((prop, i) => {
                    return <PropertyCard property={prop} onClick={() => this.props.history.push(`/property/${prop.id}`)}/>
									})
								}
							</Card.Group>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Properties;