import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import Select from "react-select";
import TagsInput from "react-tagsinput";

import Button from "components/CustomButton/CustomButton.jsx";
import { getAllUserRoles } from 'api/user';
import {checkValidEmail} from 'utils/validation'
import SweetAlert from "react-bootstrap-sweetalert";
import { sendInviteEmail } from 'api/mail'


class InviteUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailTags: [],
      userRole: 4,
      userRoleSelect: [],

      alert: null
    }
  }

  componentDidMount() {
    getAllUserRoles()
      .then(x => {
        const roleList = x.map(x => {
          return {'value': x.id, 'label': x.name}
        });
        this.setState({ userRoleSelect: roleList })
      })
  }

  handleEmailTags = (emailTags) => {
    const emails = emailTags.filter(x => checkValidEmail(x));
    this.setState({ emailTags: emails });
  }

  submitForm = (e) => {
    e.preventDefault();
    const data = {
      emails: this.state.emailTags,
      role: this.state.userRole
    }

    sendInviteEmail(data)
      .then(() => this.sendSuccessAlert())
      .catch(() => alert('Could not send emails, try again later'));
  }

  sendSuccessAlert = () => {
    this.setState({ alert: (
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Sent Invites"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="info"
      />
    )});
  }

  hideAlert = () => {
    this.setState({ alert: null });
  }


  render() {
    return (
      <Grid fluid>
        <Row>
          {this.state.alert}
          <Col md={12}>
            <FormGroup>
              <ControlLabel>Emails</ControlLabel>
              <TagsInput
                value={this.state.emailTags}
                onChange={this.handleEmailTags}
                tagProps={{ 
                  className: "react-tagsinput-tag tag-fill tag-azure",
                }}
                inputProps={{ 
                  placeholder: "Enter email, then click enter" 
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>User Type</ControlLabel>
              <Select
                placeholder="User Role"
                name="userRole"
                options={this.state.userRoleSelect}
                value={this.state.userRole}
                onChange={value => this.setState({userRole: value.value})}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button
            bsStyle="info"
            fill
            pullRight
            onClick={this.submitForm}
            >
            Invite User
            </Button>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default InviteUserForm;
