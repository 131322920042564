import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import {
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import SwitchArrows from 'assets/svg/SwitchArrows';


class HeaderLinks extends Component {

  constructor(props) {
    super(props);
    let notifications = [];
    if (this.props.notifications) {
      notifications = this.props.notifications.map( (x, i)=> {
      return (<MenuItem key={i}>{x.title}</MenuItem>)
      });
    }
    this.state = { notifications: notifications, width: window.innerWidth, isAdminPage: this.props.isAdminPage };

  }

  render() {
    let tipText;
    let switchHref;
    if (this.state.isAdminPage) {
      tipText = 'User Dashboard';
      switchHref = '/dashboard';
    } else {
      tipText = 'Admin Dashboard';
      switchHref = '/admin/dashboard';
    }
    const switchView = <Tooltip id="view">{tipText}</Tooltip>;

    const switchComponent = this.state.width >= 992 ? (
      <OverlayTrigger placement="left" overlay={switchView}>
        <NavItem eventKey={9.9} href={switchHref}>
          <SwitchArrows />
        </NavItem>
      </OverlayTrigger>
    ) : (
      <ul className='nav'>
        <li>
          <NavLink
            to={switchHref}
            className="nav-link"
            activeClassName="active"
            onClick={() => this.setState({isAdminPage: !this.state.isAdminPage})}
          >
            <p>{tipText}</p>
          </NavLink>
        </li>
      </ul>
    )
    const adminActions = (
      <React.Fragment>
        {switchComponent}
      <NavDropdown
        eventKey={2}
        title={
          <div>
            <i className="fa fa-plus" />
            <p className="hidden-md hidden-lg">
              Actions
              <b className="caret" />
            </p>
          </div>
        }
        noCaret
        id="basic-nav-dropdown-1"
      >
        <MenuItem eventKey={2.1} href='/create/property'>Create Property</MenuItem>
        <MenuItem eventKey={2.2} href='/create/entity'>Create Entity</MenuItem>
        <MenuItem eventKey={2.3} href='/create/user'>Add User</MenuItem>
        <MenuItem divider />
        <MenuItem eventKey={2.4} href='/invite'>Send Invite</MenuItem>
      </NavDropdown>
      </React.Fragment>
    )

    return (
      <div>
        <Nav pullRight>
          {this.props.isAdmin &&
            adminActions
          }

          {/* <NavDropdown
            eventKey={3}
            title={
              <div>
                <i className="fa fa-bell-o" />
                { this.state.notifications.length ? (
                  <span className="notification">{this.state.notifications.length}</span>
                ) : null
                }
                <p className="hidden-md hidden-lg">
                  Notifications
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-2"
          >
            {
              this.state.notifications.map(x => {
                return x;
              })
            }
            { 
              this.state.notifications.length && (
                <MenuItem divider />
              )
            }
            <MenuItem eventKey={999}>Notification Center</MenuItem>

          </NavDropdown> */}
          <NavDropdown
            eventKey={4}
            title={
              <div>
                <i className="fa fa-list" />
                <p className="hidden-md hidden-lg">
                  More
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-3"
            bsClass="dropdown-with-icons dropdown"
          >
            {/* <MenuItem eventKey={4.1} href='/messages'>
                <i className="pe-7s-mail" /> Messages
            </MenuItem> */}
            <MenuItem eventKey={4.2} href='/help'>
              <i className="pe-7s-help1" /> Help Center
            </MenuItem>
            <MenuItem eventKey={4.3} href={`/settings`}>
              <i className="pe-7s-tools" /> Settings
            </MenuItem>
            <MenuItem divider />
            <MenuItem eventKey={4.5} onClick={this.props.logout}>
              <div className="text-danger">
                <i className="pe-7s-close-circle" /> Log out
              </div>
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}
export default HeaderLinks;