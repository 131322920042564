import React, { Component } from "react";
import { 
  Grid, 
  Row, 
  Col,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import Select from "react-select";

import Card from "components/Card/Card";
import Button from "components/CustomButton/CustomButton.jsx";
import InviteUserForm from "components/Forms/InviteUserForm";
import { getAllUserRoles } from 'api/user';

class InvitePage extends Component {

  render() {
    return (
      <div className="main-content">
        <Card
          title='Invite Users'
          content={<InviteUserForm />}
        />
      </div>
    );
  }
}

export default InvitePage;