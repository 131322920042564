import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  HelpBlock,
  ControlLabel
} from "react-bootstrap";
import CreatableSelect from 'react-select/lib/Creatable';
import Select from "react-select";
import Datetime from "react-datetime";
import axios from 'axios';
import Checkbox from "components/Checkbox/CheckBox.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { 
  checkValidPhoneNumber,
  checkValidDateString 
} from 'utils/validation';
import { stateSelect } from 'variables/Variables';
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from 'moment';
import MediaUploader from "../Media/MediaUploader";
import './Forms.css';
import { createProperty, savePropertyFiles } from 'api/property';
import { API_URL, PYAPP_URL } from "settings";

class AddPropertyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
        property: {
            id: 0,
            name: "",
            acres: 0.0,
            purchase_price: 0.0,
            closing_date: "",
            active: false,
            address: {
                street: "",
                city: "",
                state: "",
                zip: ""
            },
            address_id: 0,
            zone_id: 0
        },      

      files: [],
      
      zones: [],
        
      //errors
      closingDateError: null,
        
      isValid: false,

      // alert
      alert: null,
    };
    
    this.mediaUploader = React.createRef();
  }

  getZones = async () => {
    axios.get(`${PYAPP_URL}/zones/`)
      .then(res => {
        var obj = this.state;
        obj.zones = res.data.data.zones.map(x => {
          return {'value': x.id, 'label': x.name};
        });
        console.log('getZones');
        console.log(obj.zones);
        this.setState(obj);
      })
      .catch(err => {
        console.log('get zones error');
        console.log(err);
        return err;
      });    
  }
  
  componentDidMount() {
    this.getZones();
  }

  getValidationState = (value, validateFunction) => {
    if (value === null || value === '') return null;
    return validateFunction(value) ? 'success' : 'error';
  }

  handleFormChange = (event) => {
    const obj = this.state;
    var name = event.target.name;
    if(name == 'street' || name == 'city' || name == 'zipcode' || name == 'state'){
        obj.property.address[name] = event.target.value;
    }else{
        obj.property[name] = event.target.value;
    }
    this.setState(obj);
    this.setState({badAttempt: false});
  }

  handleStateChange = (selectedValue) => {
    const obj = this.state;
    obj.property.address.state = selectedValue.value;
    this.setState(obj);
    this.setState({badAttempt: false});
  } 

  handleZoneChange = (selectedValue) => {
    const obj = this.state;
    obj.property.zone_id = selectedValue.value;
    this.setState(obj);
    this.setState({badAttempt: false});
  } 

  handleCheckboxChange = (event) => {
      console.log('checxbox change');      
      const obj = this.state;
      obj.property.active = event.target.checked;
      console.log(event.target.checked);
      console.log(obj);
      this.setState(obj);
  }
  
  sendSuccessAlert = () => {
    this.setState({ alert: (
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Added Property"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="info"
      />
    )});
  }

  sendErrorAlert = (tx) => {
    this.setState({ alert: (
      <SweetAlert
        error
        style={{ display: "block", marginTop: "-100px" }}
        title={tx}
        text={tx}
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="info"
      />
    )});
  }

  hideAlert = () => {
    this.setState({ alert: null });
  }

  clearState = () => {
    this.setState({
        property: {
            id: 0,
            name: "",
            acres: 0.0,
            purchase_price: 0.0,
            closing_date: "",
            active: false,
            address: {
                street: "",
                city: "",
                state: "",
                zip: ""
            },
            address_id: 0,
            zone_id: 0
        },      

      files: [],
      
      zones: [],
        
      //errors
      closingDateError: null,
        
      isValid: false,

      // alert
      alert: null,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;
    let errormsg = '';

    if(this.state.property.name == ''){
        isValid=false;
        errormsg = 'Name is missing';
    }
    
    if(this.state.property.zone_id == '' || this.state.property.zone_id == 0){
        isValid=false;
        errormsg = 'Zone is missing';
    }
    try {
      let data = {
        name: this.state.property.name,
        address: {
          street: this.state.property.address.street,
          city: this.state.property.address.city,
          state: this.state.property.address.state,
          zipcode: this.state.property.address.zipcode,
        },
        address_id: this.state.property.address_id,
        acres: this.state.property.acres,
        purchase_price: this.state.property.purchase_price,        
        zone_id: this.state.property.zone_id
      }
            
      //active value
      let active_value = this.state.property.active ? 1: 0;
      data['active'] = active_value;
        
      // add the date
      if (this.state.property.closing_date) data['closing_date'] = Moment(this.state.property.closing_date).toISOString();
      
      //files
      //data['files'] = this.mediaUploader.current.getFiles();
      
      // filter out null values
      Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);

      console.log('Will save property');
      console.log(data);
      
      if (isValid) {
        createProperty(data)
          .then(res => {
            if (res.status === 201 || res.status === 200) { 
              console.log(res);
              let propid = res.data.id;
              let datafiles = {
                pid: propid,
                files: this.mediaUploader.current.getFiles()
              }
              if(datafiles.files.length > 0){
                savePropertyFiles(datafiles).
                    then(res2 => {
                        if (res2.status === 201 || res2.status === 200) {
                            console.log(res2);
                            this.clearState();
                            this.sendSuccessAlert();
                        }else{
                            this.clearState();
                            this.sendErrorAlert('The property was added but could not save the images ('+res2.status+')');
                            console.log(res2);                                     
                        }
                    })
                    .catch(err2 => {
                        console.log('Error saving property files');
                        console.log(err2);
                        this.clearState();
                        this.sendErrorAlert('Error when saving the property');
                    });
              }else{  
                    this.clearState();
                    this.sendSuccessAlert();
              }              
            } else {
              this.sendErrorAlert('Could not add property ('+res.status+')');
              console.log(res);
            }
          })
          .catch(err => {
            console.log(err);
            this.sendErrorAlert('Error when saving the property');
          });
      }else{
          console.log('Some info is not valid');      
          this.sendErrorAlert(errormsg);          
      }
    } catch (err) {
      console.log('Error in submitting');
      console.log(err);      
      this.sendErrorAlert('Error when trying to save the property');          
    }    
  }

  render() {
    return (
        <Grid fluid>
          <form enctype="multipart/form-data">
          <Row>
            {this.state.alert}
            <Col md={6}>
              <FormGroup>
                <ControlLabel>
                    Property Name <span className="star">*</span>
                </ControlLabel>
                <FormControl
                    type="text"
                    name="name"
                    value={this.state.property.name}
                    onChange={this.handleFormChange}                    
                    required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <ControlLabel>
                        Zone <span className="star">*</span>
                    </ControlLabel>
                    <Select
                        placeholder="Zone"
                        name="zone_id"
                        options={this.state.zones}
                        value={this.state.property.zone_id}
                        onChange={this.handleZoneChange}
                        required
                    />
                </FormGroup>
            </Col>            
            </Row>
	          <Row>
							<legend>Address</legend>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>
                      Address
                  </ControlLabel>
                  <FormControl
                      type="text"
                      name="street"
                      placeholder="Street"
                      value={this.state.property.address.street}
                      onChange={this.handleFormChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>
                      City
                  </ControlLabel>
                  <FormControl
                      type="text"
                      name="city"
                      placeholder="City"
                      value={this.state.property.address.city}
                      onChange={this.handleFormChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>
                      State
                  </ControlLabel>
                  <Select
                    placeholder="State"
                    name="state"
                    options={stateSelect}
                    value={this.state.property.address.state}
                    onChange={this.handleStateChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>
                      Zip Code
                  </ControlLabel>
                  <FormControl
                      type="number"
                      placeholder="Zip Code"
                      name="zipcode"
                      value={this.state.property.address.zipcode}
                      onChange={this.handleFormChange}
                  />
                </FormGroup>
              </Col>
						</Row>
            <Row>
                <legend>Information</legend>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>
                        Acres
                    </ControlLabel>
                    <FormControl
                        type="number"
                        step="0.01"
                        name="acres"
                        value={this.state.property.acres}
                        onChange={this.handleFormChange}                    
                    />
                  </FormGroup>
                </Col>            
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>
                        Purchase Price
                    </ControlLabel>
                    <FormControl
                        type="number"
                        step="1"
                        name="purchase_price"
                        value={this.state.property.purchase_price}
                        onChange={this.handleFormChange}                    
                    />
                  </FormGroup>
                </Col>   
                <Col sm={4}>
                <FormGroup validationState={this.getValidationState(this.state.closing_date, checkValidDateString)} >
                  <ControlLabel>Closing Date</ControlLabel>
                  <Datetime
                    timeFormat={false}
                    name="closing_date"
                    inputProps={{ date_of_birth: "Select Closing Date", name: "closing_date" }}
                    value={this.state.property.closing_date}
                    onChange={value => this.setState( state => (state.property.closing_date = value, state))}
                  />
                  <FormControl.Feedback />
                  {this.state.closingDateError &&
                    <HelpBlock>Date should be of form 02/27/2019</HelpBlock>
                  }
                </FormGroup>
                </Col>                         
            </Row>
            <Row>
            <Col md={6}>
                <Checkbox 
                  name="active"
                  number={1}
                  checked={this.state.property.active}
                  onChange={this.handleCheckboxChange}
                  label="Active" 
                />
            </Col>
            </Row>            
	          <Row>
                <legend>Images</legend>              
              <Col sm={8} smOffset={2}>
                <MediaUploader ref={this.mediaUploader} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button
                bsStyle="info"
                fill
                pullRight
                submit
                onClick={this.handleSubmit}
                >
                Add Property
                </Button>
              </Col>
             </Row>              					  
              <div className="category">
              <span className="star">*</span> Required fields
              </div>
            </form>
        </Grid>
    );
  }
}

export default AddPropertyForm;