import axios from "axios";
import { API_URL, PYAPP_URL } from "settings";


export async function getGroupProperties(group_id) {
    return axios.get(`${PYAPP_URL}/group/${group_id}/properties`).then(x => x.data.objects)
}

export async function getGroup(group_id) {
    return axios.get(`${PYAPP_URL}/group/${group_id}`).then(x => x.data)
}

export async function createGroup(group) {
    return axios.post(`${PYAPP_URL}/group`, group)
        .then(x => x.data)
}

export async function getAllGroupTypes() {
    return axios.get(`${PYAPP_URL}/group_type`).then(x => x.data.objects)
}