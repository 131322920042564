import React from "react";
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: null
    };
  }

  render() {
    return (
      <div className="wizard-step">
        <h5 className="text-center">Please tell us more about yourself.</h5>
        <Row>
          <Col md={5} mdOffset={1}>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                type="text"
                name="name"
                placeholder="Property Name"
              />
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup>
              <ControlLabel>Tagline</ControlLabel>
              <FormControl
                type="text"
                name="tagline"
                placeholder="Property summary/tagline"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={10} mdOffset={1}>
            <FormGroup>
              <ControlLabel>
                Email <span className="text-danger">*</span>
              </ControlLabel>
              <FormControl
                type="email"
                name="email"
                placeholder="ex: hello@creative-tim.com"
                onChange={event => this.setState({ email: event.target.value })}
              />
              {this.state.emailError}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Step1;
