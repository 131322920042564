import React, { Component } from 'react'
import {
  Grid,
  Row,
  Col
} from 'react-bootstrap';

import BalanceSheet from 'components/Money/BalanceSheet';
import Card from 'components/Card/Card';
import MediaUploader from '../../components/Media/MediaUploader';


class Test extends Component {
  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title='Balance Sheet'
                content={<MediaUploader />}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default Test