import axios from 'axios';
import { API_URL, PYAPP_URL } from "settings";
import { queryAPI } from 'api/general';
import { getUserProperties } from 'api/user';
import { isFutureDate } from 'utils/date';


export async function getZone(zone_id) {
    return axios.get(`${PYAPP_URL}/property_zone/${zone_id}`).then(x => x.data)
}

export async function getAllZones(numPerPage=-1, pageNum=1) {
    return axios.get(`${PYAPP_URL}/property_zone?results_per_page=${numPerPage}&page=${pageNum}`).then(x => x.data.objects)
}

export async function createZone(data) {
    return axios.post(`${PYAPP_URL}/property_zone`, data)
}

