import axios from "axios";
import { API_URL, PYAPP_URL } from "settings";

export function authHeader() {
    const authToken = localStorage.getItem('authToken');
    return authToken ? 'Authorization: Bearer ' + authToken : null;
}

export async function requestProfile(token) {
    const options = {
        method: 'get',
        url: `${PYAPP_URL}/auth/status`,
        headers:{ 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };
    const ans = await axios(options)
        .then( res => res.data.data)
        .catch( err => {
            console.log(err);
            return null;
        });
    return ans;
};

// active:
// true
// address:
// {…}
// created_at:
// "Mon, 11 Mar 2019 17:29:05 GMT"
// date_of_birth:
// "Thu, 17 Sep 1992 00:00:00 GMT"
// email:
// "seanwademail@gmail.com"
// firstName:
// "Sean"
// gender:
// "M"
// id:
// 2
// lastName:
// "Wade"
// username:
// "smwade"

export async function loadProfileFromSession() {
    const token = localStorage.getItem('authToken');
    if (token) {
        const user = requestProfile(token);
        return user;
    }
    return null;
};

export async function login(email, password) {
    const data = { email, password};
    return await axios.post(`${PYAPP_URL}/auth/login`, data)    
        .then(res => res.data.auth_token)
        .then(token => {
            addSession(token);
            return token;
        })
        .catch(err => {
            console.log(err);
            return null;
        });
}

export function hasTokenExpired(exp) {
    if (exp) {
        return new Date().getTime() / 1000 < exp;
    }
    else {
        return false;
    }
}

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

export function addSession (authToken, exp, iat) {
    localStorage.setItem('authToken', authToken);
    localStorage.setItem('exp', exp);
    localStorage.setItem('iat', iat);
}

export function clearSession() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('exp');
    localStorage.removeItem('iat');
}