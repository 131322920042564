import axios from "axios";

import { API_URL, PYAPP_URL } from "settings";
import { getGroupProperties } from 'api/group'
import { queryAPI } from 'api/general'


export async function getUser(user_id, useID = true) {
    if (useID) {
        return axios.get(`${PYAPP_URL}/user/${user_id}`).then(x => x.data)
    } else {
        return queryAPI('user', `username == ${user_id}`, true).then(x => x.data)
    }
}

export async function createUser(data) {
    return axios.post(`${PYAPP_URL}/user`, data)
}

export async function checkUsernameExists(username) {
    return axios.get(`${PYAPP_URL}/user_exists/${username}`);
}

export async function checkEmailExists(email) {
    return axios.get(`${PYAPP_URL}/email_exists`, {
        params:{
            email: email
        }});
}

export async function getUserGroups(user_id) {
    return axios.get(`${PYAPP_URL}/user/${user_id}/groups`).then(x => x.data.objects)
}

export async function getUserDocuments(user_id) {
    return axios.get(`${PYAPP_URL}/user/${user_id}/documents`).then(x => x.data.objects)
}

export async function getUserProperties(user_id) {
    try {
        let groups = await getUserGroups(user_id);
        let prop_list = [];
        for (let i in groups) {
            let properties = await getGroupProperties(groups[i].id);
            prop_list.push(...properties)
        }
        return prop_list
    } 
    catch (e) {
        return [];
    }
}

export async function getAllUsers(numPerPage=-1, pageNum=1) {
    return axios.get(`${PYAPP_URL}/user?results_per_page=${numPerPage}&page=${pageNum}`).then(x => x.data.objects)
}

export async function getAllUserRoles() {
    return axios.get(`${PYAPP_URL}/role`).then(x => x.data.objects);
}