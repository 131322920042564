import React, { Component } from 'react';
import { 
  Grid, 
  Row, 
  Col,
  FormGroup,
  ControlLabel,
	FormControl
} from "react-bootstrap";
import CreatableSelect from 'react-select/lib/Creatable';
import Select from "react-select";

import SimpleUserTable from 'components/Tables/SimpleUserTable';
import Button from "components/CustomButton/CustomButton.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";

import { getAllUsers } from 'api/user';
import { getAllProperties } from 'api/property';
import { getAllGroupTypes } from 'api/group';


class CreateEntityForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			name: null,
			address: {},
			groupTypeId: null,
			addressId: null,
			taxId: null,
			contactPersonId: 2, // FIXME:
			propertyId: null,

			// Dropdowns
			propertyOptions: [],
			propertySelect: null,
			typeOptions: [],
			typeSelect: null,
			investorOptions: [],
			investorSelect: null,

			allUsers: [],
			allGroupTypes: [],
			allProperties: [],
			investors: []
		};
	}

	componentWillMount() {
		getAllUsers()
			.then(x => this.setState({ allUsers: x}))
			.then(() => {
				const l = this.state.allUsers.map(x => {
					if (x.username !== 'admin') {
						return {'value': x, 'label': x.first_name + ' ' + x.last_name};
					} else {
						return {'value': x, 'label': 'admin'};
					} 
				});
				this.setState({investorOptions: l});
			});
		getAllProperties()
			.then(x => this.setState({ allProperties: x}))
			.then(() => {
				const l = this.state.allProperties.map(x => {return {'value': x.id, 'label': x.name}});
				this.setState({propertyOptions: l});
			});
		getAllGroupTypes()
			.then(x => this.setState({ allGroupTypes: x}))
			.then(() => {
				const l = this.state.allGroupTypes.map(x => {return {'value': x.id, 'label': x.name}});
				this.setState({typeOptions: l});
			});
	}

	addInvestor = () => {
		const newInvestor = this.state.investorSelect.value;
		this.setState({investors: this.state.investors.concat([newInvestor])});
	};

	removeInvestor = (e) => {
		this.setState({investors: this.state.investors.filter(function(investor) { 
						return investor !== e
				})});
	}

	submitForm = (e) => {

		e.preventDefault();
		const group = {
			name: this.state.name,
			group_type_id: this.state.entityTypeId,
			address_id: this.state.addressId,
			tax_id: this.state.taxId,
			contact_person_id: this.state.contactPersonId,
			property_id: this.state.propertyId,
			investors: this.state.investors
		}
		console.log(group)
	}

	render() {
		return (
			<div className='main-content'>
				<Grid fluid>
					<form>
					<Row>
						<Col md={12}>
            <legend>Create Entity</legend>
								<FormGroup>
									<ControlLabel>Name</ControlLabel>
									<FormControl placeholder='Entity Name' type='text'></FormControl>
								</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
								<FormGroup>
									<ControlLabel>Tax ID</ControlLabel>
									<FormControl placeholder='12-3456789' type='text'></FormControl>
								</FormGroup>
						</Col>
						<Col md={6}>
								<FormGroup>
									<ControlLabel>Name</ControlLabel>
									<FormControl 
										placeholder='Entity Name' 
										type='text'
										onUpdate={value =>
											this.setState({ name: value })
										} 
									/>
								</FormGroup>
						</Col>
					</Row>
						<Row>
							<Col md={12}>
							<FormInputs
								ncols={["col-md-12"]}
								proprieties={[
									{
										label: "Address",
										type: "text",
										bsClass: "form-control",
										placeholder: "Street",
										value: this.state.address.street,
									}
								]}
							/>
							<FormInputs
								ncols={["col-md-4", "col-md-4", "col-md-4"]}
								proprieties={[
									{
										label: "City",
										type: "text",
										bsClass: "form-control",
										placeholder: "City",
										value: this.state.address.city,
									},
									{
										label: "Country",
										type: "text",
										bsClass: "form-control",
										placeholder: "Country",
										value: this.state.address.country,
									},
									{
										label: "Postal Code",
										type: "number",
										bsClass: "form-control",
										placeholder: "ZIP Code",
										value: this.state.address.zipcode,
									}
								]}
							/>
							</Col>
						</Row>
					<Row>
						<Col md={6}>
							<FormGroup>
								<ControlLabel>Property</ControlLabel>
									<Select
										placeholder="Property"
										name="propertySelect"
										value={this.state.propertySelect}
										options={this.state.propertyOptions}
										onChange={value =>
											this.setState({ propertySelect: value })
										}
									/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<ControlLabel>Entity Type</ControlLabel>
									<CreatableSelect
										placeholder="Type (i.e LLC)"
										name="typeSelect"
										value={this.state.typeSelect}
										options={this.state.typeOptions}
										onChange={value =>
											this.setState({ typeSelect: value })
										}
									/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<legend>Add Investors</legend>
						<Col xl={6}>
							<Row>
								<Col sm={9}>
									<FormGroup>
											<Select
												placeholder="Investor Name"
												name="typeSelect"
												value={this.state.investorSelect}
												options={this.state.investorOptions}
												onChange={value =>
													this.setState({ investorSelect: value })
												}
											/>
									</FormGroup>
								</Col>
								<Col sm={3}>
									<FormGroup>
										<ControlLabel> </ControlLabel>
										<Button bsStyle='success' onClick={this.addInvestor} fill wd>Add Investor</Button>
									</FormGroup>
								</Col>
							</Row>
						</Col>
						<Col xl={6}>
							<FormGroup>
								<ControlLabel>Selected Investors</ControlLabel>
								<SimpleUserTable users={this.state.investors} action={this.removeInvestor} />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Button bsStyle="info" fill pullRight onClick={this.submitForm}>
								Submit
							</Button>
						</Col>
					</Row>
          </form>
				</Grid>
			</div>
		)
	}
}

export default CreateEntityForm;