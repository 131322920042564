import React, { Component } from 'react'
import {
		FormGroup,
		FormControl,
		ControlLabel,
		HelpBlock
} from 'react-bootstrap';

import Button from 'components/CustomButton/CustomButton'

export class ExampleForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
			value: '',
			valid: true
    };
  }

  getValidationState() {
    const length = this.state.value.length;
    if (length > 5) return 'success';
    else if (length > 0) return 'error';
    return null;
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
	}
	
	handleSubmit = (e) => {
		e.preventDefault();
		console.log(this.state.value)
	}

  render() {
    return (
      <div>
				<form onSubmit={this.handleSubmit}>
				<FormGroup
					controlId="formBasicText"
					validationState={this.getValidationState()}
				>
          <ControlLabel>Workiing example with validation</ControlLabel>
          <FormControl
            type="text"
            value={this.state.value}
            placeholder="Enter text"
						onChange={this.handleChange}
						required
          />
          	<FormControl.Feedback />
          {this.state.valid &&
          <React.Fragment>
     		    <HelpBlock>Validation is based on string length.</HelpBlock>
     		    <HelpBlock>Validation is based on string length.</HelpBlock>
     		    <HelpBlock>Validation is based on string length.</HelpBlock>
     		    <HelpBlock>Validation is based on string length.</HelpBlock>
</React.Fragment>
					}
        </FormGroup>
				<FormGroup
					controlId="formBasicText"
					validationState={this.getValidationState()}
				>
          <ControlLabel>Workiing example with validation</ControlLabel>
          <FormControl
            type="text"
            value={this.state.value}
            placeholder="Enter text"
						onChange={this.handleChange}
						required
          />
          	<FormControl.Feedback />
					{this.state.valid &&
     		    <HelpBlock>Validation is based on string length.</HelpBlock>
					}
        </FormGroup>
        <Button submit>Submit</Button>
				</form>
      </div>
    )
  }
}

export default ExampleForm