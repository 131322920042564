import React, { Component } from "react";
// react component that creates a form divided into multiple steps
import StepZilla from "react-stepzilla";
import { Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";

import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from "./Step3.jsx";

const steps = [
  { name: "First Tab", component: <Step1 /> },
  { name: "Second Tab", component: <Step2 /> },
  { name: "Third Tab", component: <Step3 /> }
];

class Wizard extends Component {
  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12} lg={10} lgOffset={1}>
              <Card
                wizard
                id="wizardCard"
                textCenter
                title="Property Wizard"
                category="Step by step walkthrough to add a property"
                content={
                  <StepZilla
                    steps={steps}
                    stepsNavigation={false}
                    nextButtonCls="btn btn-prev btn-info btn-fill pull-right btn-wd"
                    backButtonCls="btn btn-next btn-default btn-fill pull-left btn-wd"
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

/*
    __tablename__ = 'property'
    id = Column(Integer, primary_key=True, autoincrement=True)
    name = Column(String(256), nullable=False)
    acres = Column(Float)
    purchase_price = Column(Float)
    capitalization_price = Column(Float)
    closing_date = Column(DateTime)
    active = Column(Boolean, default=True, nullable=False)
    created_at = Column(DateTime, default=func.now(), nullable=False)
    updated_at = Column(DateTime, default=func.now(), nullable=False)

    property_tax = Column(Float)
    zone_id = Column(Integer, ForeignKey('property_zone.id'))

    address_id = Column(Integer, ForeignKey('address.id'))
    address = db.relationship('Address', backref='properties')
    zone = db.relationship('PropertyZone', backref='properties')


*/

export default Wizard;