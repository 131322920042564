import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";


class PasswordResetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true
    };
  }

 async componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      400
    );
  }

  render() {
    return (
      <Grid>
        <Row>
          <div style={{height: '150px'}}></div> {/* TODO: Fix this hack */}
        </Row>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Reset Password"
                content={
                  <div>
                    <p>Enter your email address and we will send you a link to reset your password.</p>
                    <FormGroup>
                      <FormControl 
                        name="formEmail" 
                        placeholder="email" 
                        onChange={this.handleFormChange}
                        value={this.state.formEmail}
                        type="text" 
                      />
                    </FormGroup>
                  </div>
                }
                legend={
                  <Button bsStyle="info" onClick={this.handleFormSubmit} fill wd>
                    Send password reset email
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default PasswordResetPage;
