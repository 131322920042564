import React from "react";


const CheckBox = (props) => {
    return (
      <div className="checkbox">
        <input
          id={props.number}
          type="checkbox"
          onChange={props.onChange}
          checked={props.checked}
        />
        <label htmlFor={props.number}>{props.label}</label>
      </div>
    )
}

export default CheckBox;
