import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import StatsCard from "components/Card/StatsCard";
import Card from "components/Card/Card";
import ChartistGraph from "react-chartist";

import PropertyMap from 'components/Map/PropertyMap';

import { getAllProperties } from 'api/property';
import { isFutureDate } from 'utils/date';
import Numeral from 'numeral';
import {  getPropertyEvents } from 'api/property';

import Timeline from 'components/Timeline/Timeline';

// Visualization Ideas
// ------------------------
// Types of properties chart
// Profit and loss bar
// Aquistion line/bar chart
// 
//
//
//
//
//
//
//


class AdminDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      numAllProps: 0,
      numActiveProps: 0,
      properties: [],
      entities: [],
      events: [],

      entitiesPie: {
        labels: ["I", "LLC"],
        series: [40, 90],
        names: ['I', 'LLC']
      }
    }
  }

  componentWillMount() {
    getAllProperties()
      .then(x => this.setState({properties: x}))
      .then(() => this.setState({ 
        numActiveProps: 5
      }));
    getPropertyEvents(2)
      .then(x => this.setState({events: x}));
  }

  render() {


    const dataSales = {
      labels: [
        "9:00AM",
        "12:00AM",
        "3:00PM",
        "6:00PM",
        "9:00PM",
        "12:00PM",
        "3:00AM",
        "6:00AM"
      ],
      series: [
        [287, 385, 490, 492, 554, 586, 698, 695],
        [67, 152, 143, 240, 287, 335, 435, 437],
        [23, 113, 67, 108, 190, 239, 307, 308]
      ]
    };

    const optionsSales = {
      low: 0,
      high: 800,
      showArea: false,
      height: "245px",
      axisX: {
        showGrid: false
      },
      lineSmooth: true,
      showLine: true,
      showPoint: true,
      fullWidth: true,
      chartPadding: {
        right: 50
      }
    };

    const responsiveSales = [
      [
        "screen and (max-width: 640px)",
        {
          axisX: {
            labelInterpolationFnc: function(value) {
              return value[0];
            }
          }
        }
      ]
    ];



    return (
     <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={4} >
              <StatsCard
                bigIcon={< i className="pe-7s-wallet text-success" />}
                statsText='Active Properties'
                statsValue={this.state.numActiveProps * 3}
                footer={false}
              />
            </Col>
            <Col md={4} >
              <StatsCard
                bigIcon={< i className="pe-7s-cash text-success" />}
                statsText='Profit'
                statsValue={Numeral(1453324).format('$0,00.')}
                footer={false}
              />
            </Col>
            <Col md={4} >
              <StatsCard
                bigIcon={< i className="pe-7s-users text-success" />}
                statsText='Investors'
                statsValue={89}
                footer={false}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} >
              <StatsCard
                bigIcon={< i className="pe-7s-wallet text-info" />}
                statsText='Open Proerties'
                statsValue={2}
                footer={false}
              />
            </Col>
            <Col md={4} >
              <StatsCard
                bigIcon={< i className="pe-7s-cash text-danger" />}
                statsText='Expenses'
                statsValue={Numeral(-30549).format('$0,00.')}
                footer={false}
              />
            </Col>
            <Col md={4} >
              <StatsCard
                bigIcon={< i className="pe-7s-users text-info" />}
                statsText='New Investors'
                statsValue={3}
                footer={false}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
                <Card
                  content={
                    <PropertyMap 
                      data={this.state.properties} 
                      center={{lat: 33.4259, lng: -112.024909}}
                      zoom={7}
                    />
                  }
                />
            </Col>
          </Row>
          <Row className='equal'>
            <Col md={6}>
              <Card
                title="Users Behavior"
                category="24 Hours performance"
                content={
                  <ChartistGraph
                    data={dataSales}
                    type="Line"
                    options={optionsSales}
                    responsiveOptions={responsiveSales}
                  />
                }
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> Open
                    <i className="fa fa-circle text-danger" /> Click
                    <i className="fa fa-circle text-warning" /> Click Second
                    Time
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-history" /> Updated 3 minutes ago
                  </div>
                }
              />
            <Card
                title="Entity Types"
                content={<ChartistGraph data={this.state.entitiesPie} type="Pie" />}
              />
              </Col>
            <Col md={6}>
              <Card
                title={'Recent Events'}
                content={
                  <Timeline events={this.state.events}/>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AdminDashboard;