import React, { Component } from "react";
import { 
  Grid, 
  Row, 
  Col
} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

import AdminPropertyTable from "components/Tables/AdminPropertyTable";
import Card from "../../components/Card/Card";
import { getAllProperties } from 'api/property';


class AdminProperties extends Component {

  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      alert: null
    };
  };

  componentDidMount() {
    getAllProperties().then(x => this.setState({ properties: x }));
  }

  handleChange = (event) => {
    const obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  successAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{display: "block",marginTop: "-100px"}}
          title="Good Job Kid"
          onConfirm = {() => this.setState({alert: null})}
          onCancel = {() => this.setState({alert: null})}
          confirmBtnBsStyle="info"
        >
        Hi there
        </SweetAlert>
      )
    })
  }

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card content={ <AdminPropertyTable properties={this.state.properties}/> } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AdminProperties;
