import React from "react";
import { NavLink } from "react-router-dom";
import ReactTable from "react-table";
import Numeral from "numeral";
import Moment from "moment";

import Button from "components/CustomButton/CustomButton.jsx";


const AdminPropertyTable = (props) => {

    const tableProperties = props.properties.map((prop, key) => {
          return {
            id: key,
            name: prop.name,
            purchase_price: Numeral(prop.purchase_price).format('$,0.00'),
            closing_date: Moment(prop.closing_date).format('MM/DD/YYYY'),
            zone: prop.zone.name,
            actions: (
              <div className="actions-right">
                <NavLink to={`/property/${prop.id}`}>
                  <Button
                  bsStyle="info"
                  simple
                  icon
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                </NavLink>
                <NavLink to={`/property/${prop.id}/edit`}>
                  <Button
                  bsStyle="warning"
                  simple
                  icon
                  >
                    <i className="fa fa-edit" />
                  </Button>
                </NavLink>
              </div>
            )
          };
    })

    return (
        <ReactTable
        data={tableProperties}
        filterable
        columns={[
            {
            Header: "Name",
            accessor: "name"
            },
            {
            Header: "Purchase Price",
            accessor: "purchase_price"
            },
            {
            Header: "Closing Date",
            accessor: "closing_date"
            },
            {
            Header: "Zone",
            accessor: "zone"
            },
            {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false
            }
        ]}
        defaultPageSize={10}
        showPaginationBottom={true}
        className="-striped -highlight"
        />
    )
}


export default AdminPropertyTable;