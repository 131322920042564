import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";

import Card from 'components/Card/Card';
import { getUserGroups } from 'api/user'
import { loadProfileFromSession } from 'utils/auth';
import EntityTable from 'components/Tables/EntityTable';



class Entities extends Component {

  constructor(props) {
    super(props);
    this.state = {
      groups: [],
    }
  }

  async componentDidMount() {
    loadProfileFromSession()
      .then(x => this.setState({ profile: x}))
      .then(() => getUserGroups(this.state.profile.id))
      .then(x => this.setState({groups: x}));
  }


  render() {
    const propsList = this.state.groups.length !== 0 ? (
      <Table striped hover responsive>
        <thead>
          <tr>
            <th key={1}>Entity</th>
            <th key={1}>Property Name</th>
            <th key={2}>Percentage</th>
          </tr>
        </thead>
        <tbody>
          {this.state.groups.map((x, key) => {
            return (
              <tr key={key} onClick={() => this.props.history.push(`/entity/${x.id}`)}>
                <td>{x.name}</td>
                <td>{x.name}</td>
                <td className='text-center'>{x.id}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    ) : ( null )

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col>
              <Card 
                title='My Entities'
                content={
                  <EntityTable 
                    data={this.state.groups} 
                    onClick={(entityId) => this.props.history.push(`/entity/${entityId}`)} 
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Entities;
