import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

class NotFoundPage extends Component {
  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col>
              <h1>404 - Not Found</h1>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default NotFoundPage;