import axios from "axios";
import { API_URL, PYAPP_URL } from "settings";
import { getUserProperties } from "./user";
import { isFutureDate } from 'utils/date';


export function parseQueryString(query) {
    let [name, op, val] = query.split(' ');
    return [{name, op, val}];
}

export async function queryAPI(endpoint, query, isSingle=false) {
	return axios.get(`${PYAPP_URL}/${endpoint}`, {params: {q: {filters: parseQueryString(query), single: isSingle}}});
}

// export async function getNumberActiveProperties(userId) {
//     return new Promise(function(resolve, reject) {
//         setTimeout(function() {
//           resolve(2344);
//         }, 100);
//       });
// }
