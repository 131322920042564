import React, { Component } from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Grid,
  Row,
  Col,
  HelpBlock
} from "react-bootstrap";
import axios from 'axios';

import Select from "react-select";
import Datetime from "react-datetime";

import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import UserCard from "components/Card/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { 
  checkValidEmail,
  checkValidUsername,
  checkValidPhoneNumber,
  checkValidDateString 
} from 'utils/validation';
import { checkUsernameExists, checkEmailExists } from 'api/user';

import SweetAlert from "react-bootstrap-sweetalert";
import { stateSelect } from 'variables/Variables';
import avatar from "assets/img/faces/face-0.jpg";
import { API_URL, PYAPP_URL } from "settings";
import Moment from 'moment';
import 'components/Forms/Forms.css';

const FormError = (props) => (
  <div style={{paddingTop: '7px'}}>
    <small className="text-danger">
      {props.message}
    </small>
  </div>
)

class UserPageEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: props.match.params.username,
      editMode: false,
      user: {
        address:{}
      },
      alert: null,
      // errors
      emailError: false,
      usernameError: false,
      phoneNumberError: false,
      birthDateError: false
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  getUser = (username) => {
    axios.get(`${PYAPP_URL}/users/${this.state.username}`)
      .then(res => {
        if(res.data.data.date_of_birth){
            res.data.data.date_of_birth = Moment(res.data.data.date_of_birth).format('MM/DD/YYYY'); 
        }
        this.setState({ user: res.data.data, editMode: true })
      })
      .catch(err => err);
  };

  componentDidMount() {
    this.getUser();
  }

  getValidationState = (value, validateFunction) => {
    if (value === null || value === '' || !this.state.editMode) return null;
    return validateFunction(value) ? 'success' : 'error';
  }

  handleFormChange = (event) => {
    const obj = this.state;
    var name = event.target.name;
    if(name == 'street' || name == 'city' || name == 'zipcode' || name == 'state'){
        obj.user.address[name] = event.target.value;
    }else if (name == 'notes'){        
        //nothing for now
    }else if(name == 'firstname'){
        obj.user['firstName'] = event.target.value;
    }else if (name == 'lastname'){
        obj.user['lastName'] = event.target.value;
    }else{
        obj.user[name] = event.target.value;
    }
    this.setState(obj);
    this.setState({badAttempt: false});
  }

  handleStateChange = (selectedValue) => {
    const obj = this.state;
    obj.user.address.state = selectedValue.value;
    this.setState(obj);
    this.setState({badAttempt: false});
  } 
   
  sendSuccessAlert = () => {
    this.setState({ alert: (
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Saved User"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="info"
      />
    )});
  }

  sendErrorAlert = (tx) => {
    this.setState({ alert: (
      <SweetAlert
        error
        style={{ display: "block", marginTop: "-100px" }}
        title={tx}
        text={tx}
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="info"
      />
    )});
  }

  hideAlert = () => {
    this.setState({ alert: null });
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;
    let errormsg = '';
    
    // check required
    if (!checkValidEmail(this.state.user.email)) {
      this.setState({emailError: true});
      isValid = false;
      errormsg = 'Email is not valid';    
    }else{
      let rese = await checkEmailExists(this.state.user.email);
      if (rese.data.data != 0 && rese.data.data != this.state.user.id) {
        this.setState({emailError: true});
        isValid = false;
        errormsg = 'Email already exists';
        console.log('check email exists error. user id ' + this.state.user.id);
        console.log(rese);
      }        
    } 
    if (!checkValidUsername(this.state.user.username)) {
      isValid = false;
      errormsg = 'Username is not valid';
    } else {
      let res = await checkUsernameExists(this.state.user.username);
      if (res.data.data != 0 && res.data.data != this.state.user.id) {
        this.setState({usernameError: true});
        isValid = false;
        errormsg = 'Username already exists';
        console.log('checkUsernameExists error');
        console.log(res);
      }
    }

    console.log('Handling user profile edit submit')
    let data = {
      username: this.state.user.username,
      first_name: this.state.user.firstName,
      last_name: this.state.user.lastName,
      email: this.state.user.email,
      phone_number: this.state.user.phone_number,
      address: {
        street: this.state.user.address.street,
        city: this.state.user.address.city,
        state: this.state.user.address.state,
        zipcode: this.state.user.address.zipcode,
      },
    };
    if (this.state.user.date_of_birth) data['date_of_birth'] = Moment(this.state.user.date_of_birth).toISOString();
    console.log(data);

    if (!isValid) {       
      console.log('Some info is not valid');      
      this.sendErrorAlert(errormsg);  
      /*this.setState({
        emailError: <FormError message='Email should be of the form john@doe.com'/>
      })*/
    }
    else {
      console.log('Will save the user');  
      axios.put(`${PYAPP_URL}/user/${this.state.user.id}`, data)  
        .then( res => {
          console.log(res);
          this.setState({username: res.username});
          this.sendSuccessAlert();
        })
        .catch( err => {
          console.log(err)
          this.sendErrorAlert('Username or email already exist.');
        })
    }
  }

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            {this.state.alert}
            <Col md={8}>
              <Card
                title="User Profile"
                content={
                  <form method="POST">
                    <Row>
                      <Col md={6}>  
                          <FormGroup validationState={this.getValidationState(this.state.user.email, checkValidEmail)} >
                            <ControlLabel>
                                Email address <span className="star">*</span>
                            </ControlLabel>
                            <FormControl
                                type="email"
                                name="email"
                                onChange={this.handleFormChange}
                                value={this.state.user.email}
                                disabled={!this.state.editMode}                                
                                required
                            />
                            <FormControl.Feedback />
                            {this.state.emailError &&
                              <HelpBlock>Invalid Email</HelpBlock>
                            }
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup validationState={this.getValidationState(this.state.user.username, checkValidUsername)} >
                            <ControlLabel>
                                Username <span className="star">*</span>
                            </ControlLabel>
                            <FormControl
                                type="text"
                                name="username"
                                onChange={this.handleFormChange}
                                value={this.state.user.username}
                                disabled={!this.state.editMode}                                
                                required
                            />
                            <FormControl.Feedback />
                            {this.state.usernameError &&
                              <HelpBlock>Username is taken</HelpBlock>
                            }
                          </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                              <ControlLabel>
                                  First Name
                              </ControlLabel>
                              <FormControl
                                  type="text"
                                  name="firstname"
                                  placeholder="First name"
                                  value={this.state.user.firstName}
                                  onChange={this.handleFormChange}
                                  disabled={!this.state.editMode}
                              />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                              <ControlLabel>
                                  Last Name
                              </ControlLabel>
                              <FormControl
                                  type="text"
                                  name="lastname"
                                  placeholder="Last name"
                                  value={this.state.user.lastName}
                                  onChange={this.handleFormChange}
                                  disabled={!this.state.editMode}
                              />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <FormGroup validationState={this.getValidationState(this.state.user.phone_number, checkValidPhoneNumber)} >
                          <ControlLabel>
                              Phone Number
                          </ControlLabel>
                          <FormControl
                              type="text"
                              name="phone_number"
                              value={this.state.user.phone_number}
                              onChange={this.handleFormChange}
                          />
                          <FormControl.Feedback />
                          {this.state.phoneNumberError &&
                            <HelpBlock>(444) 444-4444</HelpBlock>
                          }
                        </FormGroup>
                      </Col>                    
                      <Col sm={6}>
                        <FormGroup validationState={this.getValidationState(this.state.user.date_of_birth, checkValidDateString)} >
                          <ControlLabel>Birth Date</ControlLabel>
                          <Datetime
                            timeFormat={false}
                            name="date_of_birth"
                            inputProps={{ date_of_birth: "Select Birthday", name: "date_of_birth" }}
                            value={this.state.user.date_of_birth}
                            onChange={value => this.setState( state => (state.user.date_of_birth = value, state))}
                          />
                          <FormControl.Feedback />
                          {this.state.birthDateError &&
                            <HelpBlock>Date should be of form 02/27/2019</HelpBlock>
                          }
                        </FormGroup>
                      </Col>                    
                    </Row>
                    <Row>               			
                    <Col md={12}>
                    <FormGroup>
                      <ControlLabel>
                          Address
                      </ControlLabel>
                      <FormControl
                          type="text"
                          name="street"
                          placeholder="Street"
                          value={this.state.user.address.street}
                          onChange={this.handleFormChange}
                          disabled={!this.state.editMode}
                      />
                    </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <ControlLabel>
                              City
                          </ControlLabel>
                          <FormControl
                              type="text"
                              name="city"
                              placeholder="City"
                              value={this.state.user.address.city}
                              onChange={this.handleFormChange}
                              disabled={!this.state.editMode}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup validationState="null">
                          <ControlLabel>
                              State
                          </ControlLabel>
                          <Select 
                            placeholder="State"
                            name="state"
                            options={stateSelect}
                            value={this.state.user.address.state}
                            onChange={this.handleStateChange}
                            disabled={!this.state.editMode}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <ControlLabel>
                              Zip Code
                          </ControlLabel>
                          <FormControl
                              type="number"
                              placeholder="Zip Code"
                              name="zipcode"
                              value={this.state.user.address.zipcode}
                              onChange={this.handleFormChange}
                              disabled={!this.state.editMode}
                          />
                        </FormGroup>
                      </Col>
				    </Row>

                    <Button bsStyle="info" pullRight fill type="submit" onClick={this.handleFormSubmit}>
                      Update Profile
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            <Col md={4}>
              <UserCard
                bgImage="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
                avatar={avatar}
                name={this.state.user.firstName + " " + this.state.user.lastName}
                userName={this.state.username}
                description={
                  <span>
                    "This is a description
                    <br />
                    About the user
                    <br />
                    That they can change
                  </span>
                }
                socials={
                  <div>
                    <Button simple>
                      <i className="fa fa-facebook-square" />
                    </Button>
                    <Button simple>
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button simple>
                      <i className="fa fa-google-plus-square" />
                    </Button>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default UserPageEdit;
