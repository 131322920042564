import Dashboard from "views/Dashboard/Dashboard.jsx";
import Properties from "views/Properties/Properties.jsx";
import Entities from "views/Entities/Entities.jsx";
import AdminDashboard from "views/Admin/AdminDashboard.jsx";
import AdminUsers from "views/Admin/AdminUsers.jsx";
import AdminProperties from "views/Admin/AdminProperties.jsx";
import AdminEntities from "views/Admin/AdminEntities.jsx";
import AdminZones from "views/Admin/AdminZones.jsx";
import DocumentManager from "views/DocumentManager/DocumentManager.jsx";

import Wizard from "views/Property/Wizard/Wizard";
import UserPage from "views/User/UserPage";
import UserPageEdit from "views/User/UserPageEdit";
import UserPageSettings from "views/User/UserPageSettings";
import PropertyPage from "views/Property/PropertyPage";
import PropertyPageEdit from "views/Property/PropertyPageEdit";
import DealRoom from "views/DealRoom/DealRoom";
import EntityPage from "views/Entities/EntityPage";
import InvitePage from "views/User/InvitePage";
import NotFoundPage from "views/Error/NotFoundPage";
import HelpCenter from "views/Help/HelpCenter";
import SettingsPage from "views/Settings/SettingsPage";
import SettingsAccountPage from "views/Settings/SettingsAccountPage";
import SettingsProfilePage from "views/Settings/SettingsProfilePage";
import SettingsPropertiesPage from "views/Settings/SettingsPropertiesPage";
import SettingsSecurityPage from "views/Settings/SettingsSecurityPage";
import SettingsNotificationsPage from "views/Settings/SettingsNotificationsPage";
import SettingsEntitiesPage from "views/Settings/SettingsEntitiesPage";
import ZonePageEdit from "views/Zone/ZonePageEdit";

import Distribution from "components/Distribution/Distribution";
import AdminNotifications from "views/Admin/AdminNotifications";
import Calendar from "views/Calendar/Calendar";

import CreateProperty from "views/Properties/CreateProperty";
import CreateEntity from "views/Entities/CreateEntity";
import CreateUser from "views/User/CreateUser";

import Test from "views/Test/Test";

export const subRoutes = [
  {
    path: "/test",
    component: Test
  },
  {
    path: "/dealroom",
    component: DealRoom
  },
  {
    path: "/invite",
    component: InvitePage
  },
  {
    path: "/distribution",
    component: Distribution
  },
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/create/property",
    component: CreateProperty
  },
  {
    path: "/create/entity",
    component: CreateEntity
  },
  {
    path: "/create/user",
    component: CreateUser
  },
  {
    path: "/user/:username",
    component: UserPage
  },
  {
    path: "/user/:username/edit",
    component: UserPageEdit
  },
  {
    path: "/user/:username/settings",
    component: UserPageSettings
  },
  {
    path: "/entity/:entity_id",
    component: EntityPage
  },
  {
    path: "/property/:prop_id",
    component: PropertyPage
  },
  {
    path: "/property/:prop_id/edit",
    component: PropertyPageEdit
  },
  {
    path: "/property-wizard",
    component: Wizard
  },
  {
    path: "/zone/:zone_id/edit",
    component: ZonePageEdit
  },
  {
    path: "/help",
    component: HelpCenter
  },
  {
    path: "/settings",
    component: SettingsAccountPage
  },
  {
    path: "/404",
    component: NotFoundPage
  }
];

export const dashboardRoutes = [
  {
    collapse: true,
    path: "/admin",
    name: "Admin",
    state: "openAdmin",
    icon: "pe-7s-science",
    requireAdmin: true,
    views: [
      {
        path: "/admin/users",
        name: "Users",
        mini: "-",
        component: AdminUsers
      },
      {
        path: "/admin/properties",
        name: "Properties",
        mini: "-",
        component: AdminProperties
      },
      {
        path: "/admin/notifications",
        name: "Notifications",
        mini: "-",
        component: AdminNotifications
      },
      {
        path: "/admin/entities",
        name: "Entities",
        mini: "-",
        component: AdminEntities
      },
      {
        path: "/admin/zones",
        name: "Zones",
        mini: "-",
        component: AdminZones
      }
    ]
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    requireAdmin: false,
    component: Dashboard
  },
  {
    path: "/properties",
    name: "Properties",
    icon: "pe-7s-home",
    requireAdmin: false,
    component: Properties
  },
  {
    path: "/entities",
    name: "Entities",
    icon: "pe-7s-id",
    requireAdmin: false,
    component: Entities
  },
  {
    path: "/documents",
    name: "Documents",
    icon: "pe-7s-note2",
    requireAdmin: false,
    component: DocumentManager
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "pe-7s-date",
    requireAdmin: false,
    component: Calendar
  },
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];