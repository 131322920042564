import React from "react";
import { Table } from "react-bootstrap";
import './Table.css';

const PropertyTable = (props) => {
    return  props.data.length !== 0 ? (
        <Table striped hover responsive>
          <thead>
            <tr>
              <th key={1}>#</th>
              <th key={1}>Property Name</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((x, key) => {
              return (
                <tr className='pointer' key={key} onClick={() => props.onClick(x.id)}>
                  <td>{key + 1}</td>
                  <td>{x.name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : ( null )

} 

  

export default PropertyTable;