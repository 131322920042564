import React, { Component } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

// import { GOOGLE_MAPS_API_KEY } from "settings";
const GOOGLE_MAPS_API_KEY = 'AIzaSyAvLRanim_mOHAMOfLzMnvJ1b8eivx8H1M';


class PropertyMap extends Component {
  RegularMap = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap
        defaultZoom={this.props.zoom}
        defaultCenter={{ lat: this.props.center.lat, lng: this.props.center.lng }}
        defaultOptions={{
          scrollwheel: true
        }}
      >
      {this.props.data.map((p, i) => {
        return <Marker key={i} position={{ lat: p.address.lat, lng: p.address.lng }} />
      })

      }
      </GoogleMap>
    ))
  );

  render() {
    return (
      <div>
        <this.RegularMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `500px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            />
      </div>
    );
  }
}
export default PropertyMap;