import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Nav,
  NavItem,
  Tab
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import { getGroup } from 'api/group';
import BalanceSheet from 'components/Money/BalanceSheet';


class EntityPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.entity_id,
      entity: {}
    }
  }

  componentDidMount() {
    getGroup(this.state.id)
      .then(x => this.setState({
        entity: x
      }));
  }

  render() {
		const infoTab = (
			<div className='tab-page'>
				<Row>
					<Col md={12}>
						<div className='desc-section'>
							<h3>Balance Sheet</h3>
							<BalanceSheet />
							</div>
					</Col>
				</Row>
			</div>
		);
		const investorsTab = (
			<div className='tab-page'>
				<Row>
					<Col md={12}>
						<h3>Tab</h3>
						<p>This is a tab</p>
					</Col>
				</Row>
			</div>
		);
		const SettingsTab = (
			<div className='tab-page'>
				<Row>
					<Col md={12}>
						<h3>Tab</h3>
						<p>This is a tab</p>
					</Col>
				</Row>
			</div>
		);

    return (

      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={this.state.entity.name}
                content={
									<Tab.Container id="tabs-with-dropdown" defaultActiveKey="info">
										<Row className="clearfix">
											<Col sm={12}>
												<Nav bsStyle="tabs">
													<NavItem eventKey="info">
														<i className="fa fa-info" /> Info
													</NavItem>
													<NavItem eventKey="investors">
														<i className="fa fa-cube" /> Investors
													</NavItem>
													<NavItem eventKey="settings">
														<i className="fa fa-cog" /> Settings
													</NavItem>
												</Nav>
											</Col>
											<Col sm={12}>
												<Tab.Content animation>
													<Tab.Pane eventKey="info">
													 {infoTab}
													</Tab.Pane>
													<Tab.Pane eventKey="investors">
													 {investorsTab}
													</Tab.Pane>
													<Tab.Pane eventKey="settings">
													 {SettingsTab}
													</Tab.Pane>
												</Tab.Content>
											</Col>
										</Row>
									</Tab.Container>
                }
              />
            </Col>
            <Col md={4}>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EntityPage;