import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  HelpBlock,
  ControlLabel
} from "react-bootstrap";
import CreatableSelect from 'react-select/lib/Creatable';
import Select from "react-select";
import Datetime from "react-datetime";

import Checkbox from "components/Checkbox/CheckBox.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { createZone } from 'api/zone';
import SweetAlert from "react-bootstrap-sweetalert";
import './Forms.css';

class AddZoneForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zone: {
          id: 0,  
          name: "",  
          description: "",
      },      
      
      isValid: false,

      // alert
      alert: null,
    };
  }

  componentDidMount() {
  }

  getValidationState = (value, validateFunction) => {
    if (value === null || value === '') return null;
    return validateFunction(value) ? 'success' : 'error';
  }

  sendSuccessAlert = () => {
    this.setState({ alert: (
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Added Zone"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="info"
      />
    )});
  }

  sendErrorAlert = (tx) => {
    this.setState({ alert: (
      <SweetAlert
        error
        style={{ display: "block", marginTop: "-100px" }}
        title={tx}
        text={tx}
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="info"
      />
    )});
  }

  handleFormChange = (event) => {
    const obj = this.state;
    var name = event.target.name;
    obj.zone[name] = event.target.value;
    this.setState(obj);
    this.setState({badAttempt: false});
  }

  hideAlert = () => {
    this.setState({ alert: null });
  }

  clearState = () => {
    this.setState({
      zone: {
          id: 0,  
          name: "",  
          description: "",
      },      

      // alert
      alert: null,
    });
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;
    let errormsg = '';
    
    try {
      let data = {
        name: this.state.zone.name,
        description: this.state.zone.description
      }

      // filter out null values
      Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);

      console.log(isValid);
      console.log(data);

      if (isValid) {
        createZone(data)
          .then(res => {
            if (res.status === 201 || res.status === 200) { 
              console.log(res);  
              this.clearState();
              this.sendSuccessAlert();              
            } else {
              this.sendErrorAlert('Could not add zone ('+res.status+')');
            }
          })
          .catch(err => console.log(err));
      }else{
          console.log('Some info is not valid');      
          this.sendErrorAlert(errormsg);          
      }
    } catch (err) {
      console.log('Error in submitting');
      console.log(err);      
      this.sendErrorAlert('Error when trying to save the zone');          
    }
  }

  render() {
    return (
        <Grid fluid>
          <form>
          <Row>
            {this.state.alert}
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>
                      Name
                  </ControlLabel>
                  <FormControl
                      type="text"
                      name="name"
                      value={this.state.zone.name}
                      onChange={this.handleFormChange}
                      required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>
                      Description
                  </ControlLabel>
                  <FormControl
                      componentClass="textarea"
                      name="description"
                      rows="3"
                      value={this.state.zone.description}
                      onChange={this.handleFormChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button
                bsStyle="info"
                fill
                pullRight
                submit
                onClick={this.handleFormSubmit}
                >
                Register
                </Button>
              </Col>
            </Row>
              <div className="category">
              <span className="star">*</span> Required fields
              </div>
            </form>
        </Grid>
    );
  }
}

export default AddZoneForm;