import LoginPage from "views/LoginViews/LoginPage.jsx";
import RegisterPage from "views/LoginViews/RegisterPage.jsx";
import LockScreenPage from "views/LoginViews/LockScreenPage.jsx";
import PasswordResetPage from "views/LoginViews/PasswordResetPage.jsx";

var pagesRoutes = [
  {
    path: "/login",
    name: "Login Page",
    mini: "LP",
    component: LoginPage
  },
  {
    path: "/register",
    name: "Register",
    mini: "RP",
    component: RegisterPage
  },
  {
    path: "/password_reset",
    name: "Password Reset",
    mini: "PR",
    component: PasswordResetPage
  },
  {
    path: "/lock-screen",
    name: "Lock Screen",
    mini: "LS",
    component: LockScreenPage
  }
];

export default pagesRoutes;
