import React, { Component } from 'react'
import {
  Grid,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
  FormGroup,
  FormControl,
  ControlLabel
} from 'react-bootstrap';
import Datetime from "react-datetime";
import Select from "react-select";
import "react-select/dist/react-select.css";

import Button from "components/CustomButton/CustomButton.jsx";
import Moment from 'moment';
import Numeral from "numeral";

import './BalanceSheet.css';


let income = [
  {
    title: "Example Title",
    date: new Date(),
    amount: 539345873.543 
  },
  {
    title: "Example Title",
    date: new Date(),
    amount: 539345873.543 
  },
  {
    title: "Example Title",
    date: new Date(),
    amount: 539345873.543 
  },
  {
    title: "Example Title",
    date: new Date(),
    amount: 539345873.543 
  }
];

let expenses = [
  {
    title: "Example Title",
    date: new Date(),
    amount: -539345873.543 
  },
  {
    title: "Example Title",
    date: new Date(),
    amount: -539345873.543 
  }
];

let transactions = [...expenses, ...income];


const BalanceTableEntry = (props) => {
  const remove = <Tooltip id="remove">Remove</Tooltip>;
  return (
    <tr>
      <td></td>
      <td className="text-center"></td>
      <td>{Moment(props.date).format('MM/DD/YYYY')}</td>
      <td>{props.title}</td>
      <td>{Numeral(props.amount).format('$,0.00')}</td>
      <td className="td-actions text-right">
        <OverlayTrigger placement="top" overlay={remove}>
          <Button simple bsStyle="danger" bsSize="xs" onClick={(e) => props.deleteRow(e)}>
            <i className="fa fa-times" />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
    )
  }

const BalanceTable = (props) => {

  return (
    <div>
    <div className='balance-table'>
    <Table responsive striped hover >
    <thead>
      <tr>
        <th className='col-xs-1'></th>
        <th></th>
        <th>Date</th>
        <th>Title</th>
        <th>Amount</th>
        <th className="text-right col-xs-1">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><h2 className='section-title'>Income</h2></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      {props.income.map((x,i) => {
        return (
          <BalanceTableEntry 
            key={i}
            lineNum={i}
            title={x.title}
            date={Moment(x.date).format('MM/DD/YYYY')}
            amount={Numeral(x.amount).format('$,0.00')}
          />
        )
      })
      }
      <tr>
        <td colSpan="6"><h2 className='section-title'>Expenses</h2></td>
      </tr>
      {props.expenses.map((x,indx) => {
        return (
          <BalanceTableEntry 
            key={indx}
            title={x.title}
            date={x.date}
            amount={x.amount}
          />
        )
      })
      }
    </tbody>
    </Table>
    </div>
    </div>
  )
}


class BalanceSheet extends Component {

  constructor(props) {
    super(props);
    this.state = {

      income: [],
      expenses: [],

      date: new Date(),
      title: '',
      amount: 0,
      isIncome: true,
    }
  }

  componentWillMount() {
    this.setState({
      income: income,
      expenses: expenses
    });
  }

  deleteRow = (event) => {
    console.log(event.value);
  }

 handleChange = (event) => {
   const obj = this.state;
   obj[event.target.name] = event.target.value;
   this.setState(obj)
  }

  submitForm = (e) => {
    e.preventDefault();
    console.log('clicked');
    // TODO: Validation
    let newEntry = {
      title: this.state.title,
      date: this.state.date,
      amount: this.state.amount
    }
    if (this.state.isIncome === true) {
      this.setState({income: [...this.state.income, newEntry]})
    } else {
      this.setState({expenses: [...this.state.expenses, newEntry]})
    }
    console.log(newEntry);
  }

  render() {

  const typeSelectOptions =[
    { value: true, label: "Income" },
    { value: false, label: "Expense" },
  ];

  return (
    <div>
      <Grid fluid>
        <form>
        <Col sm={2}>
          <FormGroup>
            <Datetime
              timeFormat={false}
              name='date'
              inputProps={{ placeholder: "Date" }}
              value={this.state.date}
              onChange={value => this.setState({date: value})}
            />
          </FormGroup>
        </Col>
        <Col sm={5}>
          <FormGroup>
            <FormControl 
              name="title"
              placeholder="Title" 
              type="text"
              value={this.state.title}
              onChange={this.handleChange}
            />
          </FormGroup>
        </Col>
        <Col sm={2}>
          <FormGroup>
            <FormControl 
              name="amount"
              placeholder="Amount"
              type="text" 
              value={this.state.amount}
              onChange={this.handleChange}
            />
          </FormGroup>
        </Col>
        <Col sm={2}>
          <FormGroup>
          <Select
            placeholder="Income"
            name="isIncome"
            value={this.state.isIncome}
            options={typeSelectOptions}
            onChange={value => this.setState({isIncome: value.value})}
          />
          </FormGroup>
        </Col>
        <Col sm={1}>
          <FormGroup>
          <i 
            className="fa fa-plus-circle go-icon fa-3x" 
            onClick={this.submitForm}
            />
          </FormGroup>
        </Col>
        </form>
        <Row>
          <Col md={12}>
            <BalanceTable income={this.state.income} expenses={this.state.expenses} deleteRow={this.deleteRow}/>
          </Col>
        </Row>
      </Grid>
      
    </div>
    )
  }
}

export default BalanceSheet
