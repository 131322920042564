import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Nav,
  NavItem,
  Tab,
  ProgressBar
} from "react-bootstrap";

import { getProperty, getPropertyImages, getPropertyDocuments, getPropertyEvents } from 'api/property';
import Card from "components/Card/Card.jsx";
import PropertyMap from "components/Map/PropertyMap";
import Gallery from 'components/Media/Gallery';
import MediaUploader from 'components/Media/MediaUploader';
import Timeline from 'components/Timeline/Timeline';
import { DATA_URL } from "settings";

import './PropertyPage.css'


const PropertyRow = (props) => (
  <div className='property-section'>
    <Row>
      <Col xs={6}>
        <p>{props.name}</p>
      </Col>
      <Col xs={6}>
        <p>{props.value}</p>
      </Col>
    </Row>
    <hr/>
  </div>
)

const PropertySide = (props) => {
  const prop = props.prop;
  return (
    <div className='propdery-side'>
      <PropertyRow name='Investment Type' value={'Equity'} />
      <PropertyRow name='Est Resturn' value={prop.est_return} />
      <PropertyRow name='Closing Date' value='07/10/2019' />
      <PropertyRow name='Percent Share' value='20%' />
      <ProgressBar
        bsStyle="success"
        now={prop.percent_funded * 100}
        className="progress-line-info"
        srOnly
      />

    </div>
  )
}




class PropertyPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      prop_id: props.match.params.prop_id,
      property: {},
      address: {lat: 55, lng: 66},
      events: [],
      documents: [],
      images: []
    }
  }

  componentDidMount() {
    getProperty(this.state.prop_id)
      .then(x => this.setState({
        property: x,
        address: x.address
      }));
    getPropertyImages(this.state.prop_id)
      .then(x => this.setState({images: x}));
    getPropertyDocuments(this.state.prop_id)
      .then(x => this.setState({documents: x}));
    getPropertyEvents(this.state.prop_id)
      .then(x => this.setState({events: x}));
  }


  render() {
		let lat = this.state.address.lat;
    let lng = this.state.address.lng;
    console.log(lat)
    const images = this.state.images.map(x => {
      return {
        original: x.url,
        thumbnail: x.url,
        description: x.name
      }
    });

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={this.state.property.name}
                content={
                  <React.Fragment>
                    <Row>
                      <Col md={8}>
                        <Gallery 
                          items={images} 
                          showPlayButton={false} 
                        />
                      </Col>
                      <Col md={4}>
                        <PropertySide prop={this.state.property} />
                      </Col>
                    </Row>
									<Tab.Container id="tabs-with-dropdown" defaultActiveKey="info">
										<Row className="clearfix">
											<Col sm={12}>
												<Nav bsStyle="tabs">
													<NavItem eventKey="info">
														<i className="fa fa-info" /> Info
													</NavItem>
													<NavItem eventKey="media">
														<i className="fa fa-image" /> Maps
													</NavItem>
													<NavItem eventKey="news">
														<i className="fa fa-cube" /> News
													</NavItem>
												</Nav>
											</Col>
											<Col sm={12}>
												<Tab.Content animation>
													<Tab.Pane eventKey="info">
                            <div style={{height: 400}}>
                            <Row>
                              <Col md={8}>
                              <div className='desc-section'>
                                <h3>Description</h3>
                                <hr/>
                                <p>This is a general description about the property. It can include things like the current 
                                  state of the investment, future plans, and why it was chosen.</p> 
                              </div>
                              <div className='desc-section'>
                                <h3>Investment Highlights</h3>
                                <hr/>
                                <ul>
                                  <li>Highlight number 1</li>
                                  <li>Highlight number 2</li>
                                  <li>Highlight number 3</li>
                                  <li>Highlight number 4</li>
                                </ul>
                              </div>
                              </Col>
                            </Row>
                            </div>
													</Tab.Pane>
													<Tab.Pane eventKey="news">
                            <Timeline events={this.state.events}/>
													</Tab.Pane>
													<Tab.Pane eventKey="media">
                            <Row>
                              <Col md={10} mdOffset={1}>
														    <PropertyMap data={[this.state]} zoom={9} center={{lat:lat, lng:lng}} />
                              </Col>
                            </Row>
													</Tab.Pane>
												</Tab.Content>
											</Col>
										</Row>
									</Tab.Container>
    </React.Fragment>
                }
              />
            </Col>
            <Col md={4}>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default PropertyPage;