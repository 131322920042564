import React from 'react';
import { NavLink } from "react-router-dom";

import './SettingsSidebar.css';


const SettingsSidebar = (props) => {
    return (
      <nav className='menu'>
        <ul>
          <li><NavLink to='/settings/profile'>Profile</NavLink></li>
          <li><NavLink to='/settings/account'>Account</NavLink></li>
          <li><NavLink to='/settings/notifications'>Notifications</NavLink></li>
          <li><NavLink to='/settings/security'>Security</NavLink></li>
          <li><NavLink to='/settings/properties'>Properties</NavLink></li>
          <li><NavLink to='/settings/groups'>Groups</NavLink></li>
        </ul>
      </nav>
    )
}

export default SettingsSidebar;
