import React, { Component, useState } from 'react'
import ReactDOM from 'react-dom'

import { FilePond, File, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import './MediaUploader.css';
import { API_URL, PYAPP_URL } from "settings";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginImageCrop, FilePondPluginImageTransform)


class MediaUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {files: []};
    }
    
    handleMediaUploaderUpdate = (fileItems) => {
        console.log('handle media uploader update');
        this.setState({files: fileItems.map(fileItem => {
            console.log(fileItem.serverId);
            return fileItem.file;   
        })});
    }
    
    handleMediaUploaderOnLoad = (resp) => {
        console.log('on load');
        console.log(resp);    
    }
    
    getFiles = () => {
        console.log('get files');
        console.log(this.state);        
        return this.state.files.map(x => {return {'name': x.name};});
    }
  
    render(){  
      return (
        <div className="media-uploader">
          <FilePond
            files={this.state.files}
            allowMultiple={true}
            onupdatefiles={this.handleMediaUploaderUpdate}
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            server={`${PYAPP_URL}/general/upload`}            
            allowImageCrop={true}
            imageCropAspectRatio='16:9'
            allowImageTransform={true}
            imageTransformOutputStripImageHead={false}
            imagePreviewHeight={256}
          />
        </div>
    )}
}

export default MediaUploader
