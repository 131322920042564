import React, { Component } from 'react'

import ImageGallery from 'react-image-gallery';
import './Gallery.css';

// TODO: Add full screen
class Gallery extends Component {
  render() {
    return (
			<div className='gallery'>
				<ImageGallery 
					items={this.props.items} 
					showPlayButton={false} 
					showFullscreenButton={false}
				/>
			</div>
		)
  }
}

export default Gallery
