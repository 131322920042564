import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import logo from 'assets/img/full-logo-white.png';

class PagesHeader extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      width: window.innerWidth
    };
  }
  // function that sets the class to active of the active page
  activeRoute(routeName) {
    return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // function that shows/hides sidebar on responsive
  mobileSidebarToggle(e) {
    document.documentElement.classList.toggle("nav-open");
  }
  updateWidth() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWidth.bind(this));
  }
  render() {
    return (
      <Navbar
        collapseOnSelect
        inverse
        className="navbar-primary navbar-transparent navbar-absolute"
      >
        <Navbar.Header>
          <Navbar.Brand>
              <a href={"http://langleyland.com"} className="nav-link">
                <img src={logo} height='62px'/>
              </a>
          </Navbar.Brand>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
        <Navbar.Collapse>
          <ul className="nav navbar-nav navbar-right">
            <li className={this.activeRoute("login-page")}>
              <NavLink to={"/login"} className="nav-link" onClick={this.mobileSidebarToggle}>
                <i className="fa fa-drivers-license-o" />
                <p>Login</p>
              </NavLink>
            </li>
            <li className={this.activeRoute("register-page")}>
              <NavLink to={"/register"} className="nav-link" onClick={this.mobileSidebarToggle}>
                <i className="fa fa-user-circle-o" />
                <p>Register</p>
              </NavLink>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default PagesHeader;
