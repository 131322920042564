const user = {
	'firstName': 'Sean',
	'lastName': 'Wade',
	'username': 'smwade',
	'password': 'faonc845n235tnfe',
	'email': 'seanwademail@gmail.com',
	'admin': true,
	'address': {
		'street': '4471 E. Olive Ave',
		'city': 'Gilbert',
		'state': 'AZ',
		'zip': 85234
	}
}

module.exports = user;
