import React, { Component } from "react";
import {
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import { NavLink } from "react-router-dom";

import SettingsPageLayout from "./SettingsPageLayout";
import Button from "components/CustomButton/CustomButton.jsx";
import './Settings.css';


class SettingsAccountPage extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <SettingsPageLayout
        content={
          <div>
            <div className='section'>
              <h3>Change Password</h3>
              <hr/>
              <FormGroup>
                <ControlLabel>
                  Old Password
                </ControlLabel>
                <FormControl
                  type='password'
                  name='oldPassword'
                  onChange={x => console.log(x)}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>
                  New Password
                </ControlLabel>
                <FormControl
                  type='password'
                  name='newPassword'
                  onChange={x => console.log(x)}
                />
              </FormGroup>
              <FormGroup>
              <ControlLabel>
                Confirm password:
              </ControlLabel>
              <FormControl
                  type="password"
                  name="confimPassword"
                  onChange={event => console.log(event)}
              />
              </FormGroup>
              <p className='note'>Make sure it's more at least 8 characters including a number and a lowercase letter.</p>
              <Button>Update Password</Button>
              <NavLink to='/' className='pull-right'>I forgot my password</NavLink>
            </div>
            <div className='section'>
              <h3>Change Username</h3>
              <hr/>
              <FormGroup>
              <ControlLabel>
                New Username
              </ControlLabel>
              <FormControl
                  type="text"
                  name="newUsername"
                  onChange={event => console.log(event)}
              />
              </FormGroup>
              <Button>Change</Button>
            </div>
          </div>
        }
      />
    );
  }
}

export default SettingsAccountPage;

