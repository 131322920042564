import React, { Component } from "react";
import { 
  Grid, 
  Row, 
  Col
} from "react-bootstrap";

import Card from "../../components/Card/Card";


class AdminNotifications extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
            <Card
              content={<h1>Notifications</h1>}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AdminNotifications;
