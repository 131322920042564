import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import StatsCard from "components/Card/StatsCard";
import Card from "components/Card/Card";
import ChartistGraph from "react-chartist";

import PropertyMap from 'components/Map/PropertyMap';
import EntityTable from 'components/Tables/EntityTable';
import PropertyTable from 'components/Tables/PropertyTable';
import { getUserProperties, getUser } from 'api/user';
import { getPropertyEvents } from 'api/property';
import { isFutureDate } from 'utils/date';
import { toCurrency } from 'utils/format';
import { loadProfileFromSession } from 'utils/auth';

import Timeline from 'components/Timeline/Timeline';


class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      user: {},
      properties: [],
      entities: [],
      groups: [],
      events: [],

      numActiveProps: 0,
      numClosedProps: 0,

      totalMoney: 0,
      distributedMoney: 0,
      investedMoney: 0,

      entitiesPie: {
        labels: ["I", "LLC"],
        series: [40, 90],
        names: ['I', 'LLC']
      }
    }
  }

  getActiveProperties = (properties) => {
    return properties.filter(x => !isFutureDate(Date.parse(x.closing_date)));
  }

  getClosedProperties = (properties) => {
    return properties.filter(x => isFutureDate(Date.parse(x.closing_date)));
  }

  componentWillMount() {
    loadProfileFromSession()
      .then(x => this.setState({ profile: x}))
      .then(() => getUser(this.state.profile.id))
      .then(user => this.setState({groups:user.groups}))
      .then(() => getUserProperties(this.state.profile.id))
      .then(x => this.setState({properties: x}))
      .then(() => {
        const numActiveProps = this.getActiveProperties(this.state.properties).length;
        this.setState({
        numActiveProps: numActiveProps,
        numClosedProps: this.state.properties.length - numActiveProps
      })

    });
    getPropertyEvents(2)
      .then(x => this.setState({events: x}));
  }

  render() {


    const dataSales = {
      labels: [
        "9:00AM",
        "12:00AM",
        "3:00PM",
        "6:00PM",
        "9:00PM",
        "12:00PM",
        "3:00AM",
        "6:00AM"
      ],
      series: [
        [287, 385, 490, 492, 554, 586, 698, 695],
        [67, 152, 143, 240, 287, 335, 435, 437],
        [23, 113, 67, 108, 190, 239, 307, 308]
      ]
    };

    const optionsSales = {
      low: 0,
      high: 800,
      showArea: false,
      height: "245px",
      axisX: {
        showGrid: false
      },
      lineSmooth: true,
      showLine: true,
      showPoint: true,
      fullWidth: true,
      chartPadding: {
        right: 50
      }
    };

    const responsiveSales = [
      [
        "screen and (max-width: 640px)",
        {
          axisX: {
            labelInterpolationFnc: function(value) {
              return value[0];
            }
          }
        }
      ]
    ];

    return (
     <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={4} >
              <StatsCard
                bigIcon={< i className="pe-7s-wallet text-success" />}
                statsText='Active Properties'
                statsValue={this.state.numActiveProps}
                footer={false}
              />
            </Col>
            <Col md={4} >
              <StatsCard
                bigIcon={< i className="pe-7s-wallet text-danger" />}
                statsText='Closed Properties'
                statsValue={this.state.numClosedProps}
                footer={false}
              />
            </Col>
            <Col md={4} >
              <StatsCard
                bigIcon={< i className="pe-7s-cash text-success" />}
                statsText='Total Earning'
                statsValue={`${toCurrency(this.state.totalMoney)}`}
                footer={false}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <PropertyMap 
                    data={this.state.properties} 
                    center={{lat: 33.4259, lng: -112.024909}}
                    zoom={9}
                  />
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
                <Card
                  title='Properties'
                  content={
                    <PropertyTable 
                      data={this.state.properties} 
                      onClick={(id) => this.props.history.push(`/property/${id}`)} 
                    />
                  }
                />
            </Col>
            <Col md={6}>
                <Card
                  title='Entities'
                  content={
                    <EntityTable 
                      data={this.state.groups} 
                      onClick={(id) => this.props.history.push(`/entity/${id}`)} 
                    />
                  }
                />
            </Col>
          </Row>
          <Row className='equal'>
            <Col lg={6}>
              <Card
                title="Users Behavior"
                category="24 Hours performance"
                content={
                  <ChartistGraph
                    data={dataSales}
                    type="Line"
                    options={optionsSales}
                    responsiveOptions={responsiveSales}
                  />
                }
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> Open
                    <i className="fa fa-circle text-danger" /> Click
                    <i className="fa fa-circle text-warning" /> Click Second
                    Time
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-history" /> Updated 3 minutes ago
                  </div>
                }
              />
            <Card
                title="Entity Types"
                content={<ChartistGraph data={this.state.entitiesPie} type="Pie" />}
              />
            </Col>
            <Col md={6}>
              <Card
                title={'Recent Events'}
                content={
                  <Timeline events={this.state.events}/>
                }
                />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;