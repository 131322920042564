import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Footer from "components/Footer/Footer.jsx";
import PagesHeader from "components/Header/PagesHeader.jsx";

import NotificationSystem from "react-notification-system";

import pagesRoutes from "routes/pages.jsx";
import { style } from "variables/Variables.jsx";
import bgImage from "assets/img/desert-background.jpg";

class Pages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null
    };
  }

  componentDidMount() {
    this.setState({_notificationSystem: this.refs.notificationSystem });
  }

  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  sendNotification = (message, level, icon="pe-7s-check") => {
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className={icon} />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: level,
      position: 'tl',
      autoDismiss: 5
    });

  }

  getPageClass() {
    var pageClass = "";
    switch (this.props.location.pathname) {
      case "/login":
        pageClass = " login-page";
        break;
      case "/register":
        pageClass = " register-page";
        break;
      case "/lock-screen":
        pageClass = " lock-page";
        break;
      default:
        pageClass = "";
        break;
    }
    return pageClass;
  }

  render() {
    return (
      <div>
        <NotificationSystem ref="notificationSystem" style={style} />
        <PagesHeader />
        <div className="wrapper wrapper-full-page">
          <div
            className={"full-page" + this.getPageClass()}
            data-color="black"
            data-image={bgImage}
          >
            <div className="content">
              <Switch>
                {pagesRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.path}
                      key={key}
                      render={routeProps => (
                        <prop.component
                          {...routeProps}
                          sendNotification={this.sendNotification}
                        />
                      )}
                    />
                  );
                })}
              </Switch>
            </div>
            <Footer transparent />
            <div
              className="full-page-background"
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Pages;
