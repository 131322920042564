import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  HelpBlock,
  ControlLabel
} from "react-bootstrap";
import CreatableSelect from 'react-select/lib/Creatable';
import Select from "react-select";
import Datetime from "react-datetime";

import Checkbox from "components/Checkbox/CheckBox.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { 
  checkValidEmail,
  checkValidPassword,
  checkValidUsername,
  checkValidPhoneNumber,
  checkValidDateString 
} from 'utils/validation';
import { stateSelect } from 'variables/Variables';
import { getAllUserRoles, checkUsernameExists, checkEmailExists } from 'api/user';
import { createUser } from 'api/user';
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from 'moment';
import './Forms.css';

class AddUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      user: {
          id: 0,  
          username: "",  
          firstName: "",
          lastName: "",
          gender: null,
          date_of_birth: null,
          email: "",
          password: "",
          cfpassword: "",
          userRole: null,
          phone_number: null,
          address: {}  
      },      
      
      sendConfirmationEmail: false,

      // errors
      emailError: false,
      usernameError: false,
      passwordError: false,
      cfpasswordError: false,
      phoneNumberError: false,
      birthDateError: false,
      userRoleError: false,

      isValid: false,

      // form settings
      userRoleSelect: [],

      // alert
      alert: null,
    };
  }

  componentDidMount() {
    getAllUserRoles()
      .then(x => {
        const l = x.map(x => {
          return {'value': x.id, 'label': x.name}
        });
        this.setState({ userRoleSelect: l })
      })
  }

  getValidationState = (value, validateFunction) => {
    if (value === null || value === '') return null;
    return validateFunction(value) ? 'success' : 'error';
  }

  sendSuccessAlert = () => {
    this.setState({ alert: (
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Added User"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="info"
      />
    )});
  }

  sendErrorAlert = (tx) => {
    this.setState({ alert: (
      <SweetAlert
        error
        style={{ display: "block", marginTop: "-100px" }}
        title={tx}
        text={tx}
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="info"
      />
    )});
  }

  handleFormChange = (event) => {
    const obj = this.state;
    var name = event.target.name;
    if(name == 'street' || name == 'city' || name == 'zipcode' || name == 'state'){
        obj.user.address[name] = event.target.value;
    }else if(name == 'firstname'){
        obj.user['firstName'] = event.target.value;
    }else if (name == 'lastname'){
        obj.user['lastName'] = event.target.value;
    }else{
        obj.user[name] = event.target.value;
    }
    this.setState(obj);
    this.setState({badAttempt: false});
  }

  handleStateChange = (selectedValue) => {
    const obj = this.state;
    obj.user.address.state = selectedValue.value;
    this.setState(obj);
    this.setState({badAttempt: false});
  } 

  handleGenderChange = (selectedValue) => {
    const obj = this.state;
    obj.user.gender = selectedValue.value;
    this.setState(obj);
    this.setState({badAttempt: false});
  } 

  handleRoleChange = (selectedValue) => {
    const obj = this.state;
    obj.user.userRole = selectedValue.value;
    this.setState(obj);
    this.setState({badAttempt: false});
  } 

  handleCheckboxChage = () => {
    this.setState({sendConfirmationEmail: !this.state.sendConfirmationEmail})
  }

  hideAlert = () => {
    this.setState({ alert: null });
  }

  clearState = () => {
    this.setState({
      username: "",
      user: {
          id:0,  
          username: "",  
          firstName: "",
          lastName: "",
          gender: null,
          date_of_birth: null,
          email: "",
          password: "",
          cfpassword: "",
          userRole: null,
          phone_number: null,
          address: {
            street: "",
            city: "",
            state: "",
            zipcode: "",
          }  
      },      
      sendConfirmationEmail: true,

      // errors
      emailError: null,
      passwordError: null,
      cfpasswordError: null,

      // form settings
      userRoleSelect: [],

      // alert
      alert: null,
    });
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;
    let errormsg = '';
    
    // check required
    if (!checkValidEmail(this.state.user.email)) {
      this.setState({emailError: true});
      isValid = false;
      errormsg = 'Email is not valid';
    }else{
      let rese = await checkEmailExists(this.state.user.email);
      if (rese.data.data != 0) {
        this.setState({emailError: true});
        isValid = false;
        errormsg = 'Email already exists';
        console.log('check email exists error. user id ' + this.state.user.id);
        console.log(rese);
      }else{
        console.log('Email ok');
      }                
    }
    if (!checkValidUsername(this.state.user.username)) {
      isValid = false;
      errormsg = 'The username is not valid';
    } else {
      let res = await checkUsernameExists(this.state.user.username);
      if (res.data.data != 0) {
        this.setState({usernameError: true});
        isValid = false;
        errormsg = 'Username already exists';
        console.log('checkUsernameExists error');
        console.log(res);
      }else{
        console.log('Username ok');
      }
    }
    if (!checkValidPassword(this.state.user.password)) {
      this.setState({passwordError: true});
      isValid = false;
      errormsg = 'Password is not valid';
    } else {
      if (this.state.user.password !== this.state.user.cfpassword) {
        isValid = false;
        errormsg = 'You need to enter the same password in both boxes';
      }
    } 

    let confirmedAt = this.state.sendConfirmationEmail ? null : new Date().toISOString();
    try {
      let data = {
        username: this.state.user.username,
        email: this.state.user.email,
        password: this.state.user.password,
        first_name: this.state.user.firstName,
        last_name: this.state.user.lastName,
        gender: this.state.user.gender,
        roles: [{id: this.state.user.userRole}],
        phone_number: this.state.user.phone_number,
        address: {
          street: this.state.user.address.street,
          city: this.state.user.address.city,
          state: this.state.user.address.state,
          zipcode: this.state.user.address.zipcode,
        },
        confirmed_at: confirmedAt
      }

      // add the date
      if (this.state.user.date_of_birth) data['date_of_birth'] = Moment(this.state.user.date_of_birth).toISOString();
      
      // filter out null values
      Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);

      console.log(isValid);
      console.log(data);


      if (isValid) {
        createUser(data)
          .then(res => {
            if (res.status === 201 || res.status === 200) { 
              console.log(res);  
              this.clearState();
              this.sendSuccessAlert();              
            } else {
              this.sendErrorAlert('Could not add user ('+res.status+')');
            }
          })
          .catch(err => console.log(err));
      }else{
          console.log('Some info is not valid');      
          this.sendErrorAlert(errormsg);          
      }
    } catch (err) {
      console.log('Error in submitting');
      console.log(err);      
      this.sendErrorAlert('Error when trying to save the user');          
    }
  }

  render() {
    return (
        <Grid fluid>
          <form>
          <Row>
            {this.state.alert}
					  <legend>Login</legend>
            <Col md={6}>
              <FormGroup validationState={this.getValidationState(this.state.user.email, checkValidEmail)} >
                <ControlLabel>
                    Email address <span className="star">*</span>
                </ControlLabel>
                <FormControl
                    type="email"
                    name="email"
                    value={this.state.user.email}
                    onChange={this.handleFormChange}
                    required
                />
                <FormControl.Feedback />
                {this.state.emailError &&
                  <HelpBlock>Invalid Email</HelpBlock>
                }
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={this.getValidationState(this.state.user.username, checkValidUsername)} >
                <ControlLabel>
                    Username <span className="star">*</span>
                </ControlLabel>
                <FormControl
                    type="text"
                    name="username"
                    value={this.state.user.username}
                    onChange={this.handleFormChange}
                    required
                />
                <FormControl.Feedback />
                {this.state.usernameError &&
                  <HelpBlock>Username is taken</HelpBlock>
                }
              </FormGroup>
            </Col>
            </Row>
            <Row>
            <Col md={6}>
              <FormGroup validationState={this.getValidationState(this.state.user.password, checkValidPassword)} >
              <ControlLabel>
                  Password: <span className="star">*</span>
              </ControlLabel>
              <FormControl
                  type="password"
                  name="password"
                  value={this.state.user.password}
                  onChange={this.handleFormChange}
                  required
              />
          	  <FormControl.Feedback />
              {this.state.passwordError &&
              <React.Fragment>
                <HelpBlock> � Eight or more characters</HelpBlock>
                <HelpBlock> � At least one lower case</HelpBlock>
                <HelpBlock> � At least one upper case</HelpBlock>
                <HelpBlock> � At least one number</HelpBlock>
              </React.Fragment>
              }
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={() => this.state.user.password === this.state.user.cfpassword} >
              <ControlLabel>
                  Confirm password: <span className="star">*</span>
              </ControlLabel>
              <FormControl
                  type="password"
                  name="cfpassword"
                  onChange={this.handleFormChange}
                  value={this.state.user.cfpassword}
                  required
              />
              <FormControl.Feedback />
              {this.state.user.cfpassword !== this.state.user.password &&
                <HelpBlock>Passwords must match</HelpBlock>
              }
              </FormGroup>
            </Col>
            </Row>
            <Row>
					    <legend>Info</legend>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>
                      First Name
                  </ControlLabel>
                  <FormControl
                      type="text"
                      name="firstname"
                      value={this.state.user.firstName}
                      onChange={this.handleFormChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>
                      Last Name
                  </ControlLabel>
                  <FormControl
                      type="text"
                      name="lastname"
                      value={this.state.user.lastName}
                      onChange={this.handleFormChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup validationState={this.getValidationState(this.state.user.phone_number, checkValidPhoneNumber)} >
                  <ControlLabel>
                      Phone Number
                  </ControlLabel>
                  <FormControl
                      type="text"
                      name="phone_number"
                      value={this.state.user.phone_number}
                      onChange={this.handleFormChange}
                  />
                  <FormControl.Feedback />
                  {this.state.phoneNumberError &&
                    <HelpBlock>(444) 444-4444</HelpBlock>
                  }
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup validationState={this.getValidationState(this.state.date_of_birth, checkValidDateString)} >
                  <ControlLabel>Birth Date</ControlLabel>
                  <Datetime
                    timeFormat={false}
                    name="date_of_birth"
                    inputProps={{ date_of_birth: "Select Birthday", name: "date_of_birth" }}
                    value={this.state.user.date_of_birth}
                    onChange={value => this.setState( state => (state.user.date_of_birth = value, state))}
                  />
                  <FormControl.Feedback />
                  {this.state.birthDateError &&
                    <HelpBlock>Date should be of form 02/27/2019</HelpBlock>
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <ControlLabel>Gender</ControlLabel>
                  <Select
                    placeholder="Gender"
                    name="gender"
                    options={[{'value': 'M', 'label': 'M'}, {'value': 'F', 'label': 'F'}]}
                    value={this.state.user.gender}
                    onChange={this.handleGenderChange}
                  />
                </FormGroup>
              </Col>            
              <Col sm={6}>
                <FormGroup>
                  <ControlLabel>User Type<span className="star">*</span></ControlLabel>
                  <CreatableSelect
                      placeholder="User Type"
                      name="userSelect"
                      value={this.state.user.userRole}
                      options={this.state.userRoleSelect}
                      onChange={this.handleRoleChange}
                      required
                  />
                  <FormControl.Feedback />
                  {this.state.userRoleError &&
                    <HelpBlock>Must select User Role</HelpBlock>
                  }
                </FormGroup>
              </Col>
            </Row>
	          <Row>
							<legend>Address</legend>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>
                      Address
                  </ControlLabel>
                  <FormControl
                      type="text"
                      name="street"
                      placeholder="Street"
                      value={this.state.user.address.street}
                      onChange={this.handleFormChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>
                      City
                  </ControlLabel>
                  <FormControl
                      type="text"
                      name="city"
                      placeholder="City"
                      value={this.state.user.address.city}
                      onChange={this.handleFormChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>
                      State
                  </ControlLabel>
                  <Select
                    placeholder="State"
                    name="state"
                    options={stateSelect}
                    value={this.state.user.address.state}
                    onChange={this.handleStateChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>
                      Zip Code
                  </ControlLabel>
                  <FormControl
                      type="number"
                      placeholder="Zip Code"
                      name="zipcode"
                      value={this.state.user.address.zipcode}
                      onChange={this.handleFormChange}
                  />
                </FormGroup>
              </Col>
						</Row>
            <Row>
              <Col sm={6}>
                <Checkbox 
                  name="sendConfirmationEmail"
                  number={2}
                  checked={this.state.sendConfirmationEmail}
                  onChange={this.handleCheckboxChage}
                  label="Send Confirmation Email" 
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button
                bsStyle="info"
                fill
                pullRight
                submit
                onClick={this.handleFormSubmit}
                >
                Register
                </Button>
              </Col>
            </Row>
              <div className="category">
              <span className="star">*</span> Required fields
              </div>
            </form>
        </Grid>
    );
  }
}

export default AddUserForm;