import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import AddUserForm from 'components/Forms/AddUserForm';
import ExampleForm from 'components/ExampleForm';

class CreateUser extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title='Create New User'
                content={<AddUserForm />}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CreateUser;